import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../../assets/moviedetaillogo.png";
import BGTOP from "../../assets/MovieDetailTop.png";
import BGBOTTOM from "../../assets/MovieDetailBottom.png";
import { useNavigate, useParams } from "react-router";
import Data from "../home/Hightlightdata";

function MovieDetails() {
  const Navigate = useNavigate();
  const [isData, setData] = useState({});
  const params = useParams();

  const IMDBClicked = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const final = Data.filter((item) => item.id == params.movieid);
    setData(final[0]);
  }, []);
  return (
    <div>
      <div
        className="row"
        style={{
          backgroundImage: `url(${BGTOP})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container">
          <div className="text-center">
            <img
              src={logo}
              style={{
                width: "250px",
                height: "250px",
                cursor: "pointer",
                objectFit: "cover",
                marginBottom: "-1.5rem",
              }}
              alt="Logo"
              onClick={() => Navigate("/home")}
            />
          </div>
          <div className="row">
            <div
              className="col-lg-6 d-flex justify-content-start"
            >
              <div className="mx-4">
                <button
                  className="topbarbuttons"
                  onClick={() => Navigate("/home")}
                  style={{ color: "white" }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "white")}
                >
                  HOME
                </button>
                <button
                  className="topbarbuttons mx-3"
                  onClick={() => Navigate("/about")}
                  style={{ color: "white" }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "white")}
                >
                  ABOUT
                </button>
                <button
                  className="topbarbuttons mx-3"
                  onClick={() => Navigate("/films")}
                  style={{ color: "white" }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "white")}
                >
                  PORTFOLIO
                </button>
                <button
                  className="topbarbuttons"
                  onClick={() => Navigate("/contact")}
                  style={{ color: "white" }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "white")}
                >
                  CONTACT
                </button>
              </div>
            </div>
          </div>
          <div className="mx-4 mt-4" style={{ color: "white" }}>
            <div
              style={{
                fontFamily: "Cormorant Garamond",
                color: "white",
                fontSize: "35px",
              }}
            >
              {isData.title}
            </div>
            <div style={{ fontSize: "18px" }} className="my-1">
              {isData.year} | {isData.language} | {isData.dimension}
            </div>
            <div
              style={{
                fontFamily: "Playfair Display",
                color: "#fa9c1b",
                fontSize: "18px",
              }}
            >
              Watch Trailer
            </div>
          </div>
          <br />
        </div>
      </div>
      <div
        className="row"
        style={{
          height: "500px",
          overflow: "hidden",
          backgroundColor: "black",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={isData.video}
          allowFullScreen
        ></iframe>
      </div>
      <div
        className="row p-2"
        style={{
          backgroundImage: `url(${BGBOTTOM})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="col-lg-2 col-sm-12 col-md-4 mt-5 ">
          <img
            src={isData.image}
            style={{
              maxHeight: "100%",
              width: "100%",
              height: "350px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        </div>
        <div
          className="col-lg-8 col-sm-12 col-md-8 mt-5"
          style={{ fontFamily: "Cormorant Garamond", color: "white" }}
        >
          <div className="mb-2" style={{ fontSize: "25px" }}>
            SYNOPSIS
          </div>

          <p style={{ textAlign: "justify" }}>
            <em>{isData.description}</em>
          </p>
          <div className="mb-2" style={{ fontSize: "25px" }}>
            Crew
          </div>
          <div>
            <p style={{ textAlign: "justify" }}>
              <em>Director - {isData.director}</em>
            </p>
            <p style={{ textAlign: "justify" }}>
              <em>Writer - {isData.writer}</em>
            </p>
            <p style={{ textAlign: "justify" }}>
              <em>Music - {isData.music}</em>
            </p>
            <p style={{ textAlign: "justify" }}>
              <em>Cast - {isData.cast}</em>
            </p>
          </div>

          <div className="mb-5">
            <button
              onClick={() =>
                IMDBClicked(
                  isData.imdblink
                )
              }
              style={{ background: "#3A3839", color: "#fff" }}
            >
              VIEW IMDB PROFILE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovieDetails;
