import React from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link, Tooltip } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useNavigate } from "react-router";
import DownloadButton from "./DownloadPdf";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  const Navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="row footer-up mx-5">
          <div className="col-lg-4">
            <h6>About</h6>

            <p>
              At Ruben Trailer House, we are not just editors; we are the
              architects of cinematic excellence. Our passion for film editing
              is infused into every frame, where creativity meets precision to
              bring stories to life. With a rich legacy rooted in the heart of
              the film industry, we stand as a beacon of innovation, pushing
              boundaries, and setting new standards in the art of editing&nbsp;
              <button
                className="p-0 m-0"
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
                onClick={() => Navigate("/about")}
              >
                See more
              </button>
            </p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <h6>Address</h6>
            <p>
              Flat F3, 1st Floor
              <br />
              Door No: 14/7 (Old No.56/7)
              <br />
              Sterling Dwarakamaye Apartments
              <br />
              Giri Road (Near Amma Restaurant)
              <br />T Nagar, Chennai 600017, INDIA
              <br />
              Mobile: +91 9619675555
              <br />
              Ph: 044 452002200
            </p>
          </div>
          <div className="col-lg-2">
            <h6 style={{ margin: "0", paddingBottom: "12px" }}>Links</h6>

            <ul style={{ margin: "0", padding: "0" }}>
              <li>
                <span
                  onClick={() => Navigate("/home")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </span>
              </li>
              <li>
                <span
                  onClick={() => Navigate("/about")}
                  style={{ cursor: "pointer" }}
                >
                  About
                </span>
              </li>
              <li>
                <span
                  onClick={() => Navigate("/contact")}
                  style={{ cursor: "pointer" }}
                >
                  Contact
                </span>
              </li>
              <li>
                <span
                  onClick={() => Navigate("/films")}
                  style={{ cursor: "pointer" }}
                >
                  Portfolio
                </span>
              </li>
            </ul>
          </div>
          <div className="col-lg-2">
            <h6>Social Media</h6>
            <div className="social-icons">
              <Tooltip title="Facebook">
                <FacebookOutlinedIcon
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: "1rem",
                  }}
                  onClick={() =>
                    window.open("https://www.facebook.com/livingston.ruben")
                  }
                />
              </Tooltip>
              <Tooltip title="Twitter">
                <TwitterIcon
                  style={{
                    fontSize: "21px",
                    cursor: "pointer",
                    marginRight: "1rem",
                  }}
                  onClick={() =>
                    window.open("https://twitter.com/AntonyLRuben")
                  }
                />
              </Tooltip>
              <Tooltip title="Instagram">
                <InstagramIcon
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: "1rem",
                  }}
                  onClick={() =>
                    window.open("https://instagram.com/rth__works?igshid=MzRlODBiNWFlZA==")
                  }
                />
              </Tooltip>
              <Tooltip title="Youtube">
                <YouTubeIcon
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    marginRight: "1rem",
                  }}
                  onClick={() =>
                    window.open(
                      "https://youtube.com/@RubenTrailerHouse?feature=shared"
                    )
                  }
                />
              </Tooltip>
              <Tooltip title="LinkedIn">
                <LinkedInIcon
                  style={{ fontSize: "21px", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/97450018/admin/feed/posts/"
                    )
                  }
                />
              </Tooltip>
              <br />
              <br />
              <DownloadButton />
            </div>
          </div>
        </div>
        <hr />
        <p className="text-center">
          RUBEN TRAILER HOUSE &copy; 2023
          <br />
          All Rights Reserved
          <br />
          Site Designed & Developed By RECOMS
        </p>
      </footer>
    </>
  );
}
