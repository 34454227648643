import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MovieDetails from "../components/movie details/MovieDetails";
import Footer from "../components/footer/Footer";
import ClientPortfolio from "../components/client/ClientPortfolio";

const Home = lazy(() => import("../components/home/Home"));
const TopBar = lazy(() => import("../components/topbar/TopBar"));
const About = lazy(() => import("../components/about/About"));
const Contact = lazy(() => import("../components/contact/Contact"));
const FlimographyHome = lazy(() =>
  import("../components/flimography/FlimographyHome")
);
const WelcomeScreen = lazy(() => import("../components/welcome/WelcomeScreen"));

const LayoutWithTopBar = ({ children }) => (
  <>
    <TopBar />
    {children}
    <Footer />
  </>
);

function AppRoutes() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh", width: "100%" }}
          >
            Loading...
          </div>

        }
      >
        <Routes>
          <Route path="/" element={<WelcomeScreen />} />
          <Route
            path="/home"
            element={
              <LayoutWithTopBar>
                <Home />
              </LayoutWithTopBar>
            }
          />
          <Route
            path="/about"
            element={
              <LayoutWithTopBar>
                <About />
              </LayoutWithTopBar>
            }
          />
          <Route path="/movie-details/:movieid" element={<MovieDetails />} />
          <Route
            path="/contact"
            element={
              <LayoutWithTopBar>
                <Contact />
              </LayoutWithTopBar>
            }
          />
          <Route
            path="/films"
            element={
              <LayoutWithTopBar>
                <FlimographyHome />
              </LayoutWithTopBar>
            }
          />
          <Route
            path="/clientportfolio"
            element={
              <LayoutWithTopBar>
                <ClientPortfolio />
              </LayoutWithTopBar>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoutes;
