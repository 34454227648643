import React from "react";
import { useEffect } from "react";

function ContentTwo() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div
      style={{
        height: "40vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "32px",
        fontFamily: "Playfair Display",
        marginBottom: "-2rem",
      }}
      className="text-center video-below-content"
    >
      <em>
        "The art of selecting, shaping, and sequencing moments,<br></br>where
        the real world meets the reel world"
      </em>
    </div>
  );
}

export default ContentTwo;
