const slideData = [
  {
    id: "m1",
    title: " Jawan",

    image:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/d402e261-4f38-49bb-924d-0ba2c0e845c3/dg2gpb4-cf9a8e2e-29b2-444e-8fa9-86f615c057f2.jpg/v1/fill/w_800,h_1000,q_70,strp/jawan_fan_made_poster_2023_by_tarikulraana_dg2gpb4-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTUwMCIsInBhdGgiOiJcL2ZcL2Q0MDJlMjYxLTRmMzgtNDliYi05MjRkLTBiYTJjMGU4NDVjM1wvZGcyZ3BiNC1jZjlhOGUyZS0yOWIyLTQ0NGUtOGZhOS04NmY2MTVjMDU3ZjIuanBnIiwid2lkdGgiOiI8PTEyMDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.MSgoUG-4v0v_PTrhngMHmqPOq0heGXpMhef3qMaAOMg",

    video: "https://www.youtube.com/embed/k8YiqM0Y-78",
    director: ["Atlee"],
    assistantDirector: [],
    producedby: ["Gauri Khan"],
    cast: [
      "Shah Rukh Khan, ",
      "Nayanthara, ",
      "Vijay Sethupathi, ",
      "Deepika Padukone, ",
    ],
    crew: [],
    description:
      "A high-octane action thriller which outlines the emotional journey of a man who is set to rectify the wrongs in the society",
    imdblink: "https://www.imdb.com/title/tt15354916/?ref_=ext_shr_lnk",
    writer: ["Sumit Arora, ", "Atlee, ", "Ramanagirivasan"],
    music: ["Subhodip Sarkar"],
    year: "2023",
    dimension: "2D",
    language: ["Hindi"],
    editing: [""],
  },
  {
    id: "m2",
    title: "Theri",

    image:
      "https://images.ottplay.com/posters/ea6fbf7bf890141f490ad1d80a7466f1.jpg",

    video: "https://www.youtube.com/embed/ZK4uGLpkAKk",
    director: ["Atlee"],
    assistantDirector: [],
    producedby: [
      "A.K. Nataraj, ",
      "D. Paranthaman, ",
      "Shirley, ",
      "Kalaippuli S. Thanu",
    ],
    cast: [
      "Vijayakumar, ",
      "	Samantha Ruth Prabhu, ",
      "Amy Jackson, ",
      "Baby Nainika, ",
    ],
    crew: [],
    description:
      "When his daughter's life is endangered by a local gang, Joseph Kuruvilla will do whatever it takes to stop them. However, at the same time, his dangerous past slowly resurfaces.",
    imdblink: "https://www.imdb.com/title/tt5440700/?ref_=ext_shr_lnk",
    writer: ["Atlee, ", "Ramanagirivasan, ", "Ramanagirivasan"],
    music: ["G.V. Prakash Kumar	"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m3",
    title: " Annaatthe",

    image:
      "https://www.cinejosh.com/newsimg/newsmainimg/rajinikanth-annathe-distribution-rights-bagged-by-asian-cinemas_b_1410210911.jpg",

    video: "https://www.youtube.com/embed/zyVX8g71TGo",
    director: ["Siva"],
    assistantDirector: [],
    producedby: ["Eiqbal Jaafri, ", "Zafar Khan"],
    cast: [
      "Rajinikanth, ",
      "Keerthy Suresh, ",
      "Nayanthara, ",
      "Kushboo, ",
      "Meena, ",
    ],
    crew: [],
    description:
      "Kaaliyan has a sister who marries a young man without the permission from Kaaliyan and moves to Kolkata with her husband , where she faces unknown threats. Soon her brother Kaaliyan comes to the rescue.",
    imdblink: "https://www.imdb.com/title/tt11253090/?ref_=ext_shr_lnk",
    writer: ["Siva, ", "Chandran, ", "Pachamuthu"],
    music: ["D. Imman"],
    year: "2021",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m4",
    title: " Pushpa 2: The Rise",

    image:
      "https://i0.wp.com/moviegalleri.net/wp-content/uploads/2023/04/Allu-Arjun-Pushpa-2-First-Look-HD-Poster.jpg?ssl=1",

    video: "https://www.youtube.com/embed/Xcw4dkILsmk",
    director: ["Sukumar"],
    assistantDirector: [],
    producedby: ["Y. Ravi Shankar, ", "Naveen Yerneni, "],
    cast: ["Allu Arjun, ", "	Rashmika Mandanna, ", "Fahadh Faasil, ", "Sunil"],
    crew: [],
    description:
      "The Clash Continues as Pushpa and Bhanwar Singh continue their rivalry in this epic c onclusion to the two-parted series.",
    imdblink: "https://www.imdb.com/title/tt16539454/?ref_=ext_shr_lnk",
    writer: ["A.R. Prabhav, ", "Sukumar, ", "Srikanth Vissa"],
    music: ["Devi Sri Prasad"],
    year: "2024",
    dimension: "2D",
    language: ["Telugu", "Malayalam", "Hindi", "Kannada", "Tamil"],
    editing: [],
  },
  {
    title: "Naanum Rowdy Dhaan",
    id: "m5",
    image:
      "https://images.jdmagicbox.com/comp/jd_social/news/2018aug05/image-193198-ke8frjmprn.jpg",

    video: "https://www.youtube.com/embed/6Fj_fzW_BOo",
    director: ["Vignesh Shivan"],
    assistantDirector: [],
    producedby: ["Dhanush, ", "Nishanthan Niruthan, ", "S. Vinod Kumar"],
    cast: [
      "Vijay Sethupathi, ",
      "Nayanthara, ",
      "	RJ Balaji, ",
      "Radhika Sarathkumar, ",
      "Parthiban Radhakrishnan, ",
    ],
    crew: [],
    description:
      "A wannabe rowdy who falls for a hearing-impaired girl faces a dilemma when the girl makes a shocking request.",
    imdblink: "https://www.imdb.com/title/tt5128328/?ref_=ext_shr_lnk",
    writer: ["Vignesh Shivan"],
    music: ["Anirudh Ravichander	"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m6",
    title: "Kammatti Paadam",

    image:
      "https://m.media-amazon.com/images/M/MV5BYjdlN2U5Y2MtNmYyZS00MjZiLTk0ZDQtYjQzYzc3YzBkZjFmXkEyXkFqcGdeQXVyMjQ1ODk4ODU@.V1.jpg",

    video: "https://www.youtube.com/embed/CsxhCA5K66w",
    director: ["Rajeev Ravi"],
    assistantDirector: [],
    producedby: ["Prem Menonn, ", "Vivek Thomas, ", "Varghese, "],
    cast: [
      "Dulquer Salmaan, ",
      "	Vinayakan, ",
      "Shaun Romy, ",
      "Anil Nedumangad",
    ],
    crew: [],
    description:
      "Krishnan, who has been living away from his family and friends in Mumbai, gets a call from his friend Ganga in Kerala, out of the blue. He senses danger, and leaves for Kammattipadam, where both of them grew up.",
    imdblink: "",
    writer: ["Ajithkumar, ", "P. Balachandran, ", "Jay.k"],
    music: ["K Krishna Kumar, ", "John P. Varkey"],
    year: "2016",
    dimension: "2D",
    language: ["Malayalam"],
    editing: [],
  },
  {
    id: "m7",
    title: "King Of Kotha",

    image: "https://wallpapercave.com/wp/wp12497842.jpg",

    video: "https://www.youtube.com/embed/dljEVygvwG8",
    director: ["Abhilash Joshiy"],
    assistantDirector: [" "],
    producedby: [
      "Prassanth Kumar Chandran, ",
      " Abhay S. Dutta, ",
      "Sumit S. Rajput, ",
      "Dulquer Salmaan",
    ],
    cast: [
      "Dulquer Salmaan, ",
      "Ritika Singh, ",
      "Aishwarya Lekshmi, ",
      "Anikha Surendran, ",
      "Soubin Shahir",
    ],
    crew: [],
    description:
      "In a crime-infested town, Kannan bhai and his gang are the reigning powers. To combat this reign and seek revenge, Inspector Shahul tactfully plots the return of the 'King', leading to a transformative turn of events.",
    imdblink: "https://www.imdb.com/title/tt15134398/?ref_=ext_shr_lnk",
    writer: ["Abhilash N. Chandran"],
    music: ["Jakes Bejoy, ", "Shaan Rahman"],
    year: "2023",
    dimension: "2D",
    language: ["Malayalam"],
    editing: ["Shyam Sasidharan	"],
  },

  {
    id: "m8",
    title: "Raja Rani",

    image:
      "https://m.media-amazon.com/images/S/pv-target-images/2cc39a41c49b9601144bb163dff7b8c1f7cab49cef978406a44de56ad36baf3d.jpg",

    video: "https://www.youtube.com/embed/wZm38_0aIXk",
    director: ["Atlee"],
    assistantDirector: [],
    producedby: [
      "Ranjeet Desai, ",
      "A.R. Murugadoss, ",
      "R.Mahendran, ",
      "S. Shanmugam",
    ],
    cast: ["Arya, ", "	Nayanthara, ", "Jai, ", "Nazriya Nazim"],
    crew: [],
    description:
      "John and Regina, who dislike each other, are forced to get married and lead troubled lives. However, things take a turn when the couple suffers a sudden tragedy.",
    imdblink: "https://www.imdb.com/title/tt3203910/?ref_=ext_shr_lnk",
    writer: ["Atlee"],
    music: ["G.V. Prakash Kumar	"],
    year: "2013",
    dimension: "2D",
    language: ["Tamil"],
    editing: ["Antony L. Ruben"],
  },
  {
    id: "m9",
    title: "Vedalam",

    image:
      "https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/vedalam-et00034395-30-04-2017-11-02-00.jpg",

    video: "https://www.youtube.com/embed/b3WB7ogte-g",
    director: ["Siva"],
    assistantDirector: [],
    producedby: ["Aishwarya, ", "Ivano Fucci, ", "Jyothi Krishna, "],
    cast: ["Ajith Kumar, ", "Shruti Haasan, ", "Lakshmi Menon, ", "Soori"],
    crew: [],
    description:
      "A doting brother who works as a cab driver tries to hunt down three notorious criminals in Kolkata who had harmed his sister.",
    imdblink: "",
    writer: ["Siva, ", "G. Adi Narayana"],
    music: ["Anirudh Ravichander	"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m10",
    title: "Mersal",

    image:
      "https://popcornsg.s3.amazonaws.com/movies/650/5638-12861-Mersal.png",

    video: "https://www.youtube.com/embed/W5MWxO6JABA",
    director: ["Atlee"],
    assistantDirector: [],
    producedby: ["R.Mahendran, ", "N. Ramasamy"],
    cast: [
      "Vijay, ",
      "Nithya Menen, ",
      "S.J. Suryah, ",
      "Kajal Aggarwal, ",
      "Samantha Ruth Prabhu, ",
      "Vadivelu",
    ],
    crew: [],
    description:
      "A police officer arrests a doctor for crimes targeting medical professionals but later finds the real culprit in a tale of revenge, corruption and magic.",
    imdblink: "https://www.imdb.com/title/tt6485666/?ref_=ext_shr_lnk",
    writer: ["Atlee, ", "Vijayendra Prasad"],
    music: ["A.R. Rahman"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m12",
    title: "Remo",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/desktop-wallpaper-sivakarthikeyan-and-keerthi-suresh-in-remo-movie-stills-remo-tamil-movie-thumbnail.jpg",

    video: "https://www.youtube.com/embed/GEB4qrrWIgs",
    director: ["Bakkiyaraj Kannan"],
    assistantDirector: [],
    producedby: ["R.D. Raja"],
    cast: [
      "Sivakarthikeyan, ",
      "Keerthy Suresh, ",
      "	Anson Paul, ",
      "Saranya Ponvannan",
    ],
    crew: [],
    description:
      "All's fair in love, even SK, the protagonist, who in this gender-bender of a role masquerades as a woman to nurse his relationship with a lovely young doctor.",
    imdblink: "https://www.imdb.com/title/tt6126294/?ref_=ext_shr_lnk",
    writer: ["Bakkiyaraj Kannan", "Rishabh Purohit"],
    music: ["Anirudh Ravichander	"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: ["Antony L. Ruben"],
  },

  {
    id: "m13",
    title: "Vivegam",

    image:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ab5755e3-63d0-42b3-9496-195fa7aff19d/db48wad-9799a13d-f1d1-4013-b85e-c8aa7fdb1922.jpg/v1/fill/w_1024,h_1522,q_75,strp/vivegam_poster_design_by_mageshpinku_db48wad-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTUyMiIsInBhdGgiOiJcL2ZcL2FiNTc1NWUzLTYzZDAtNDJiMy05NDk2LTE5NWZhN2FmZjE5ZFwvZGI0OHdhZC05Nzk5YTEzZC1mMWQxLTQwMTMtYjg1ZS1jOGFhN2ZkYjE5MjIuanBnIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.JNNgSoGePKMxPBmPpt-agtVpcGToVRn5lw1YuqpOOrY",

    video: "https://www.youtube.com/embed/yJdHR8nCYWk",
    director: ["Siva"],
    assistantDirector: [],
    producedby: ["Alek Conic, ", "KS Jairam, ", "Milan Milisavljevic"],
    cast: [
      "Ajith Kumar, ",
      "Vivek Oberoi, ",
      "Kajal Aggarwal, ",
      "Akshara Haasan",
    ],
    crew: [],
    description:
      "An Interpol agent betrayed by his friends sets out to take revenge against the friends who work for a secret agency.",
    imdblink: "https://www.imdb.com/title/tt6878378/?ref_=ext_shr_lnk",
    writer: ["Siva, ", "Gautam Siddharth, ", "Mayank Jain"],
    music: ["Anirudh Ravichander	"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m14",
    title: "Veeram",

    image:
      "https://m.media-amazon.com/images/S/pv-target-images/6d6af9485e2f98a03f60b6661c60f9570f6a5eed00079841d13bc0a9020b475e.jpg",

    video: "https://www.youtube.com/embed/E2jIoTXQdxY",
    director: ["Siva"],
    assistantDirector: [],
    producedby: ["Ranjeet Desai, ", "Narravula Gopi, "],
    cast: ["Ajith Kumar, ", "Tamannaah Bhatia, ", "Vidharth, ", "Santhanam"],
    crew: [],
    description:
      "Vinayagam, an honest man, uses violence to settle disputes. He decides to mend his ways for his lover's sake. Hell breaks loose when he learns about a gang of rowdies following his lover's family.",
    imdblink: "https://www.imdb.com/title/tt3320578/?ref_=ext_shr_lnk",
    writer: ["Siva, ", "Bhupati Raja"],
    music: ["Devi Sri Prasad"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m15",
    title: "Irumbuthirai",

    image:
      "https://e1.pxfuel.com/desktop-wallpaper/27/1004/desktop-wallpaper-irumbu-thirai.jpg",

    video: "https://www.youtube.com/embed/3n3L428I8MQ",
    director: ["P.S. Mithran"],
    assistantDirector: [],
    producedby: ["Vishal"],
    cast: [
      "Vishal, ",
      "Samantha Ruth Prabhu, ",
      "	Arjun Sarja, ",
      "Delhi Ganesh",
    ],
    crew: [],
    description:
      "A soldier takes on a gang of cyber criminals when money goes missing from his account.",
    imdblink: "https://www.imdb.com/title/tt7179784/?ref_=ext_shr_lnk",
    writer: ["P.S. Mithran, ", "Pon Parthiban, ", "Rishabh Purohit"],
    music: ["Yuvan Shankar Raja	"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: ["K Kamalakannan,	", "Antony L. Ruben	"],
  },
  {
    id: "m16",
    title: "Sketch",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/SKETCH.jpg",

    video: "https://www.youtube.com/embed/ddRyInAwFl0",
    director: ["Vijay Chandar"],
    assistantDirector: [],
    producedby: ["Mayapandi"],
    cast: ["Vikram, ", "Tamannaah Bhatia, ", "Soori, ", "Sriman"],
    crew: [],
    description:
      "A vehicle hijacker who works for a loan shark gets into a trouble after stealing a car belonging to a dreaded gangster.",
    imdblink: "https://www.imdb.com/title/tt6872436/?ref_=ext_shr_lnk",
    writer: ["Vijay Chandar, ", "Mayank Jain, ", "Rishabh Purohit"],
    music: ["S. Thaman"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m17",
    title: "Dev",

    image: "https://popcornsg.s3.amazonaws.com/movies/650/11137-14304-Devjpg",

    video: "https://www.youtube.com/embed/AB2LXzNvUNo",
    director: ["Rajath Ravishankar"],
    assistantDirector: [],
    producedby: ["K.V. Durai, ", "KS Jairam, ", "S. Lakshman Kumar"],
    cast: [
      "Karthi, ",
      "Rakul Preet Singh, ",
      "Prakash Raj, ",
      "Ramya Krishnan, ",
      "Rj Vigneshkanth, ",
    ],
    crew: [],
    description:
      "Dev, a youngster from a well-to-do family, is an adventure seeker. When his friend playfully tries to set him up with Meghna, a businesswoman who has no time for love, Dev has to embark on an adventure of another kind.",
    imdblink: "https://www.imdb.com/title/tt8751976/?ref_=ext_shr_lnk",
    writer: ["Charu Gupta, ", "Rajath Ravishankar"],
    music: ["Harris Jayaraj"],
    year: "2019",
    dimension: "Tamil Telugu",
    language: ["2D"],
    editing: ["K Kamalakannan, ", "Antony L. Ruben"],
  },
  {
    id: "m18",
    title: "Viswasam",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/desktop-wallpaper-viswasam-vedalam.jpg",

    video: "https://www.youtube.com/embed/TiDyv53adt0",
    director: ["Siva"],
    assistantDirector: [],
    producedby: ["Sujit Kalidas Mukate, ", "Raahul"],
    cast: [
      "Ajith Kumar, ",
      "Nayanthara, ",
      "Jagapathi Babu, ",
      "Anikha Surendran",
    ],
    crew: [],
    description:
      "A village ruffian, who settles disputes in his native, takes on a big shot when he tries to sort out the rough patch in his marriage after several years.",
    imdblink: "https://www.imdb.com/title/tt7725626/?ref_=ext_shr_lnk",
    writer: ["Siva, ", "G. Adi Narayana"],
    music: ["D. Imman"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m19",
    title: "Pailwaan",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/wp7502484.jpg",

    video: "https://www.youtube.com/embed/C8G887cN34U",
    director: ["S Krishna"],
    assistantDirector: [],
    producedby: ["Swapna Krishna"],
    cast: ["Suniel Shetty, ", "Sushant Singh, ", "Sudeep, ", "Aakanksha Singh"],
    crew: [],
    description:
      "Krishna, a fierce wrestler, faces challenges in his personal life as he tries to fulfill the dream of his father, an ex-wrestler.",
    imdblink: "https://www.imdb.com/title/tt7317482/?ref_=ext_shr_lnk",
    writer: ["Nani Challagulla, ", "D.S. Kannan"],
    music: ["Arjun Janya"],
    year: "2019",
    dimension: "2D",
    language: ["Kannada ", "Hindi ", "Malayalam ", "Telugu ", "Tamil"],
    editing: [],
  },

  {
    id: "m20",
    title: "  Namma Veetu Pillai",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/Namma%20Veettu%20Pillai.jpg",

    video: "https://www.youtube.com/embed/59_Fl_e46IU",
    director: ["Pandiraj"],
    assistantDirector: [],
    producedby: ["Kalanithi Maran"],
    cast: [
      "Sivakarthikeyan, ",
      "	Bharathiraja, ",
      "Soori, ",
      "Aishwarya Rajesh, ",
      "Anu Emmanuel, ",
    ],
    crew: [],
    description:
      "A brother who dotes on his sister is forced into a situation where he has to get her married to a ruffian with whom he is at loggerheads. Can their relationship survive?",
    imdblink: "https://www.imdb.com/title/tt10709404/?ref_=ext_shr_lnk",
    writer: ["Pandiraj, ", "Sanjeev Tiwari, "],
    music: ["D. Imman"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m21",
    title: " Bigil",

    image:
      "https://i.pinimg.com/originals/fa/16/53/fa16534b39328059d568e6fa6ef1f08c.jpg",

    video: "https://www.youtube.com/embed/GR-Ui8-V2M0 ",
    director: ["Atlee"],
    assistantDirector: [],
    producedby: ["Kalpathi S. Agoram, ", "Kalpathi S. Ganesh"],
    cast: [
      "Joseph Vijay, ",
      "Nayanthara, ",
      "Vivek, ",
      "Kathir, ",
      "Yogi Babu, ",
    ],
    crew: [],
    description:
      "Michael gives up his dream of becoming a footballer after his father's murder. However, a friend convinces him to coach a women's football team and turn his life around.",
    imdblink: "https://www.imdb.com/title/tt9260636/?ref_=ext_shr_lnk",
    writer: ["Atlee"],
    music: ["A.R. Rahman"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m22",
    title: "Sulthan",

    image:
      "https://e1.pxfuel.com/desktop-wallpaper/506/782/desktop-wallpaper-sulthan-tamil-movie-sulthan-thumbnail.jpg",

    video: "https://www.youtube.com/embed/EWp7eDbJSCA",
    director: ["Bakkiyaraj Kannan"],
    assistantDirector: [],
    producedby: ["Aravendraj Bhaskaran, ", "S.R. Prabhu, ", "S.R. Prakashbabu"],
    cast: ["Karthi, ", "Rashmika Mandanna, ", "Napolean, ", "Nawab Shah"],
    crew: [],
    description:
      "A man raised by gangsters tries to reform them, and a hired job to protect a village presents him with the perfect opportunity.",
    imdblink: "https://www.imdb.com/title/tt11502940/?ref_=ext_shr_lnk",
    writer: ["Bakkiyaraj Kannan"],
    music: ["Vivek Mervin, ", "Yuvan Shankar Raja	"],
    year: "2021",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m23",
    title: "Bhoomi",

    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/hd/6c4e5f87599657.5dbcff2173d16.jpg",

    video: "https://www.youtube.com/embed/iGiBmwQm7Uo",
    director: ["Lakshman"],
    assistantDirector: [],
    producedby: ["G. Marimuthu ,", "Anand Joy ,", "Sujatha Vijayakumar	"],
    cast: ["Nidhhi Agerwal ,", "	Cricknowle ,", "Rnr Mahonar ,", "G. Marimuthu"],
    crew: [],
    description:
      "Bhoominathan is an astronaut at NASA who has to resolve an unfinished business in his village before taking off to Mars",
    imdblink: "https://www.imdb.com/title/tt10893170/?ref_=ext_shr_lnk",
    writer: ["Lakshman"],
    music: ["D. Imman"],
    year: "2021",
    dimension: "2D",
    language: ["D. Imman"],
    editing: [],
  },

  {
    id: "m24",
    title: "Pushpa",

    image:
      "https://www.arewablogng.com/wp-content/uploads/2022/01/Gem7QWzBNYx.jpg",

    video: "https://www.youtube.com/embed/Lk2oDvoonUc",
    director: ["Sukumar"],
    assistantDirector: [],
    producedby: ["Y. Ravi Shankar, ", "Naveen Yerneni"],
    cast: [
      "Rashmika Mandanna, ",
      "Fahadh Faasil, ",
      "Allu Arjun, ",
      "Sai Pallavi",
    ],
    crew: [],
    description:
      "The Clash Continues as Pushpa and Bhanwar Singh continue their rivalry in this epic c onclusion to the two-parted series.",
    imdblink: "https://www.imdb.com/title/tt16539454/?ref_=ext_shr_lnk",
    writer: ["A.R. Prabhav, ", "Srikanth Vissa, ", "Sukumar"],
    music: ["Devi Sri Prasad"],
    year: "2024",
    dimension: "2D",
    language: ["Telugu"],
    editing: [],
  },
  {
    id: "m25",
    title: "Etharkkum Thunindhavan",

    image: "https://wallpapercave.com/wp/wp10825754.jpg",

    video: "https://www.youtube.com/embed/cKrz-kWoaSI",
    director: ["Pandiraj"],
    assistantDirector: [],
    producedby: ["Kalanithi Maran"],
    cast: [
      "Suriya, ",
      "	Priyanka Arulmohan, ",
      "Sathyaraj, ",
      "Saranya Ponvannan",
    ],
    crew: [],
    description:
      "A lawyer turns judge jury and executioner and goes after a gang, led by a minister's son, that threatens women with videos of them.",
    imdblink: "https://www.imdb.com/title/tt10340562/?ref_=ext_shr_lnkM ",
    writer: ["M Chandramouli, ", "Pandiraj"],
    music: ["D. Imman"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m11",
    title: "AAA",

    image:
      "https://www.gethucinema.com/wp-content/uploads/2017/05/AAA-Latest-Poster-Stills-3.jpg",

    video: "https://www.youtube.com/embed/unGw3Sgt6g4",
    director: ["Adhik Ravichandran"],
    assistantDirector: [],
    producedby: ["S. Michael Rayappan"],
    cast: [
      "silambarasan Rajendar, ",
      "Tamannaah Bhatia, ",
      "Shriya Saran, ",
      "Motta Rajendran",
    ],
    crew: [],
    description:
      "A local gangster in Madurai bids adeiu to his past life. Years later in his late 50's, he falls in love with a girl which changes his life",
    imdblink: "https://www.imdb.com/title/tt6735034/?ref_=ext_shr_lnk",
    writer: ["Rishabh Purohit, ", "Adhik Ravichandran"],
    music: ["Yuvan Shankar Raja	"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m26",
    title: "Nenjuku Needhi",

    image:
      "https://m.media-amazon.com/images/M/MV5BNDlkYWI4NjEtM2NjNy00YWZiLWE1YWMtMzVhYTBkNGIyMTljXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_.jpg",

    video: "https://www.youtube.com/embed/S8Wr1IsVWBw",
    director: ["Arunraja Kamaraj"],
    assistantDirector: [],
    producedby: ["Boney Kapoor, ", "Rahul"],
    cast: [
      "Udhayanidhi Stalin, ",
      "Tanya S Ravichandran, ",
      "Suresh Chakravarthi",
    ],
    crew: [],
    description:
      "An upright city-bred police officer launches an attack against the caste system after caste-based discrimination and other crimes are dismissed.",
    imdblink: "https://www.imdb.com/title/tt12953750/?ref_=ext_shr_lnk",
    writer: [
      "Arunraja Kamaraj, ",
      "Tamizharasan Pachamuthu, ",
      "Anubhav Sinha",
    ],
    music: ["Dhibu Ninan Thomas"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m27",
    title: "Don",

    image: "https://wallpapercave.com/wp/wp10659747.jpg",

    video: "https://www.youtube.com/embed/s5ak-NY6OC8",
    director: ["Cibi Chakaravarthi"],
    assistantDirector: [],
    producedby: ["Allirajah Subaskaran, ", "Sivakarthikeyan"],
    cast: [
      "Sivakarthikeyan, ",
      "Priyanka Arul Mohan, ",
      "Soori, ",
      "Sivaangi Krishnakumar, ",
    ],
    crew: [],
    description: "Follows the life of a youngster from college to his marriage",
    imdblink: "https://www.imdb.com/title/tt10709484/?ref_=ext_shr_lnk",
    writer: ["Cibi Chakaravarthi"],
    music: ["	Anirudh Ravichander"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m28",
    title: "99 Songs",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortAds3/shortAds3/99%20SONGS.jpg",

    video: "https://www.youtube.com/embed/DYxbNLcC3eA",
    director: ["Vishwesh Krishnamoorthy"],
    assistantDirector: [],
    producedby: [
      "Karan Grover, ",
      "Karan Grover, ",
      "Debendra Jagadala, ",
      "A.R. Rahman",
    ],
    cast: ["Ehan Bhat, ", "Edilsy Vargas, ", "Tenzing Dalha, ", "Lisa Ray"],
    crew: [],
    description:
      "Challenged to compose 100 songs before he can marry the girl he loves, a tortured but passionate singer-songwriter embarks on a poignant musical journey.",
    imdblink: "https://www.imdb.com/title/tt7559180/?ref_=ext_shr_lnk",
    writer: ["Hussain Dalal, ", "Kalpradah, ", "Vishwesh"],
    music: ["A.R. Rahman"],
    year: "2019",
    dimension: "2D",
    language: ["Hindi ", "Tamil ", "Telugu"],
    editing: [],
  },

  {
    id: "m29",
    title: "Ponmagal Vandhal",

    image:
      "https://i0.wp.com/www.socialnews.xyz/wp-content/uploads/2020/03/02/Jyothika-s-Ponmagal-Vandhal-Movie-First-Look-Poster-.jpg?fit=853%2C1280&quality=80&zoom=1&ssl=1",

    video: "https://www.youtube.com/embed/vzfe8UEJFd0",
    director: ["JJ Fredrick"],
    assistantDirector: [],
    producedby: ["Jyotika ,", "Rajsekar Karpoorasundarapandian	 ,", "Suriya"],
    cast: ["Jyotika ,", "Parthiban Radhakrishnan ,", "Bhagyaraj ,", "Praveen"],
    crew: [],
    description:
      "Ponmagal Vandhal (Meaning: The Precious Princess has Arrived) is a courtroom drama that takes place in Ooty. Jyotika plays the lead in the film alongside various other veteran actors playing important roles",
    imdblink: "https://www.imdb.com/title/tt10692888/?ref_=ext_shr_lnk",
    writer: ["JJ Fredrick ,", "Pon Parthiban"],
    music: ["Govind Vasantha"],
    year: "2020",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m30",
    title: "Udanpirappe",

    image:
      "https://m.media-amazon.com/images/M/MV5BYTEzZDcwZmYtNjE3OC00YTA1LTk1NjUtZDM5ZTk1NmJkZDZmXkEyXkFqcGdeQXVyMTI1NDAzMzM0._V1_.jpg",

    video: "https://www.youtube.com/embed/Luhzp1435sI",
    director: ["Era Saravanan"],
    assistantDirector: [],
    producedby: ["Jyotika, ", "Suriya"],
    cast: ["Jyotika, ", "	M. Sasikumar, ", "Samuthirakani, ", "Kalaiyarasan"],
    crew: [],
    description:
      "A woman hopes for the reunion of two families caught in the ideological battle between her righteous but hot headed brother and her law abiding husband.",
    imdblink: "https://www.imdb.com/title/tt15175418/?ref_=ext_shr_lnk",
    writer: ["Era Saravanan"],
    music: ["D. Imman"],
    year: "2021",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m31",
    title: "Captain",

    image:
      "https://www.filmibeat.com/imgh/560x292/2022/04/captain-2022_164916369110.jpg",

    video: "https://www.youtube.com/embed/93Pu7MoKVQk",
    director: ["Shakti Soundar Rajan"],
    assistantDirector: [],
    producedby: ["K. Madhan"],
    cast: ["Arya, ", "	Aishwarya Lekshmi, ", "Adithya Menon, ", "Gokulnath"],
    crew: [],
    description:
      "A team of soldiers, led by a brave army captain, takes up a dangerous mission to go into a restricted forest area and uncover the mystery behind the unexplainable deaths of previous teams that had visited the site",
    imdblink: "https://www.imdb.com/title/tt16148654/?ref_=ext_shr_lnk",
    writer: ["Shakti Soundar Rajan"],
    music: ["D. Imman"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m32",
    title: "The Legend",

    image:
      "https://igimages.gumlet.io/tamil/home/the-legent432022m1.jpg?w=376&dpr=2.6",

    video: "https://www.youtube.com/embed/mvQK78iCxWY",
    director: ["Jerry, ", "Joseph D. Sami"],
    assistantDirector: [],
    producedby: ["Legend Saravanan"],
    cast: ["Geethika, ", "Nassar, ", "Prabhu, ", "Legend Saravanan"],
    crew: [],
    description:
      "Saravanan, a foreign-educated researcher returns to his native place in India. His family runs colleges and hospitals. He crosses swords with a filthy-rich medical mafia with only commercial intentions.",
    imdblink: "https://www.imdb.com/title/tt11497716/?ref_=ext_shr_lnk",
    writer: ["Pattukottai Prabhakar"],
    music: ["Harris Jayaraj"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m33",
    title: "Bagheera",

    image: "https://kuttymovies.de.com/poster/Bagheera_(2023)_Movie_Poster.jpg",

    video: "https://www.youtube.com/embed/dfKjyu8Oezg",
    director: ["Adhik Ravichandran, ", "Ravi Kndasamy"],
    assistantDirector: [],
    producedby: [
      "R.V. Bharathan, ",
      "G.Sampath, ",
      "Vijay Kiragandur, ",
      "P Pandiyan, ",
      "S.V.R Ravi Shankar",
    ],
    cast: ["Prabhu Deva, ", "Amyra Dastur, ", "Gopinath Ravi, ", "	Nassar"],
    crew: [],
    description:
      "Revolves around a psycho killer who has an aversion towards women, thanks to some of the heinous experiences he faced in his life.",
    imdblink: "https://www.imdb.com/title/tt11803630/?ref_=ext_shr_lnk",
    writer: ["Adhik Ravichandran"],
    music: ["Ganesan Sekar"],
    year: "2023",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m34",
    title: "Trigger",

    image:
      "https://www.themoviedb.org/t/p/original/tGk1pKoWsNgVHb1k7Rr59Hph9Hh.jpg",

    video: "https://www.youtube.com/embed/ePQ6ZWEd-UM",
    director: ["Sam Anton"],
    assistantDirector: [],
    producedby: ["Prateek Chakravorty, ", "Gokul.K, ", "Shruti Nallappa"],
    cast: [
      "C. Arunpandian, ",
      "Chinni Jayanth, ",
      "Atharvaa Murali, ",
      "	Tanya S Ravichandran",
    ],
    crew: [],
    description:
      "A Son tries to save his father who was an ex-cop from tight spot caused by a tragic incident of kidnap.",
    imdblink: "https://www.imdb.com/title/tt21653890/?ref_=ext_shr_lnk",
    writer: ["Sam Anton, ", "P.S. Mithran"],
    music: ["Ghibran"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m35",
    title: "Rendagam",

    image: "https://assets.voxcinemas.com/posters/P_HO00009355.jpg",

    video: "https://www.youtube.com/embed/ZmTvDCiqmUI",
    director: ["Fellini T.P."],
    assistantDirector: [],
    producedby: [
      "Midhun Abraham, ",
      "Arya, ",
      "Dinesh S. Devan, ",
      "Shaji Nadesan, ",
      "Kilimanoor Sanu",
    ],
    cast: [
      "Arvind Swami, ",
      "Kunchacko Boban, ",
      "Amalda Liz, ",
      "	Aadukalam Naren",
    ],
    crew: [],
    description:
      "Kichu, an idle guy dreams of making easy money. He is offered an odd job of befriending a strange man called David, who has lost his memory. Will Kichu accept the job? What unleashes next forms the crux of the story.",
    imdblink: "https://www.imdb.com/title/tt14312358/?ref_=ext_shr_lnk",
    writer: ["S. Sanjeev, ", "Sasikumaran"],
    music: ["A.H. Kaashif"],
    year: "2022",
    dimension: "2D",
    language: ["Malayalam"],
    editing: [],
  },

  {
    id: "m36",
    title: " Sardar",

    image: "https://assets.voxcinemas.com/posters/P_HO00009649.jpg",

    video: "https://www.youtube.com/embed/8OQzz_i3KFE",
    director: ["P.S. Mithran"],
    assistantDirector: [],
    producedby: ["S. Lakshman Kumar ,", "Ilkin Mahammadli"],
    cast: ["Karthi ,", "Raashi Khanna ,", "Laila ,", "K S Krishnan"],
    crew: [],
    description:
      "A spy, who is estranged from his family due to a mission, suddenly meets his police officer son.",
    imdblink: "https://www.imdb.com/title/tt14526318/?ref_=ext_shr_lnk",
    writer: ["Geevee ,", "P.S. MithranPon ,", "Parthiban"],
    music: ["G.V. Prakash Kumar	"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m37",
    title: "Dada",

    image: "https://image.tmdb.org/t/p/original/ubXuAM5bJqp2cnO7LOuYCSiKyv.jpg",

    video: "https://www.youtube.com/embed/23mMdgo0prk ",
    director: ["Ganesh K. Babu"],
    assistantDirector: [],
    producedby: ["Ambeth Kumar"],
    cast: ["Aparna Das, ", "Kavin, ", "	Bhagyaraj, ", "Aishwariyaa Bhaskaran"],
    crew: [],
    description:
      "A couple in love, accidentally become teenage parents. Unpleasant situations make them fall apart. Manikandan, left alone with his son, struggles as a single teenage father. Fate owns him, bringing Sindhu back into his life.",
    imdblink: "https://www.imdb.com/title/tt25405130/?ref_=ext_shr_lnk",
    writer: ["Ganesh K. Babu"],
    music: ["Jen Martin"],
    year: "2023",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m38",
    title: "Gatta Kushthi",

    image: "https://pbs.twimg.com/media/FldEVQ5aMAA7sef.jpg",

    video: "https://www.youtube.com/embed/VUR27LHmjw",
    director: ["Chella Ayyavu"],
    assistantDirector: [],
    producedby: ["Swetha Kalidindi, ", "Aryan Ramesh, ", "Vindhya Reddy"],
    cast: [
      "Vishnu Vishal, ",
      "Aishwarya Lekshmi, ",
      "Karunas, ",
      "Kaali Venkat",
    ],
    crew: [],
    description:
      "Veera, a male chauvinist, is seeking a bride with his own preconceived set of conditions. However, he unexpectedly ends up marrying Keerthi who is the polar opposite to his expectations. What will happen when the truth is revealed?",
    imdblink: "https://www.imdb.com/title/tt21617996/?ref_=ext_shr_lnk",
    writer: ["Chella Ayyavu"],
    music: ["Justin Prabhakaran"],
    year: "2022",
    dimension: "",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m39",
    title: "Waltair Veerayya",

    image:
      "https://m.media-amazon.com/images/M/MV5BYTQ0OTA5OTEtZWFkNy00Y2Q4LWI1NGEtNDA5OGE0Y2JjZjQxXkEyXkFqcGdeQXVyMTE5NTEyNTg5._V1_.jpg",

    video: "https://www.youtube.com/embed/AIdMYKIrHFw ",
    director: ["K.S. Ravindra"],
    assistantDirector: [],
    producedby: [
      "G.K. Mohan, ",
      "Chiranjeevi Pedamallu, ",
      "Y. Ravi Shankar, ",
      "K.V.V. Bala Subramanyam, ",
      "Naveen Yerneni",
    ],
    cast: [
      "Chiranjeevi, ",
      "Shruti Haasan, ",
      "Ravi Teja, ",
      "Urvashi Rautela",
    ],
    crew: [],
    description:
      "A fisherman who engages in smuggling accepts a request from a disheveled police officer in order to use it to his personal advantage",
    imdblink: "https://www.imdb.com/title/tt15281402/?ref_=ext_shr_lnk",
    writer: [
      "K.S. Ravindra, ",
      "Kona Venkat, ",
      "K. Chakravarthy Reddy, ",
      "K.S. Ravindra, ",
      "Hari Mohana Krishna, ",
      "Vineeth Potluri",
    ],
    music: ["Devi Sri Prasad"],
    year: "2023",
    dimension: "2D",
    language: ["Telugu"],
    editing: [],
  },

  {
    id: "m40",
    title: "Ayalaan",

    image:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/2a774691-2c1e-4c5d-9ee4-27d3623b3e51/dfvbobr-a66bd526-e79e-4623-bb02-ced65a762907.jpg/v1/fill/w_735,h_1088,q_70,strp/ayalaan_poster_design_by_stevemosesdir_dfvbobr-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTM4OCIsInBhdGgiOiJcL2ZcLzJhNzc0NjkxLTJjMWUtNGM1ZC05ZWU0LTI3ZDM2MjNiM2U1MVwvZGZ2Ym9ici1hNjZiZDUyNi1lNzllLTQ2MjMtYmIwMi1jZWQ2NWE3NjI5MDcuanBnIiwid2lkdGgiOiI8PTkzOCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.hRTxLVuce-oaZL7eZGfrSa9cvrz2wBzOLDqMarjIojc",

    video: "https://www.youtube.com/embed/YYiU2yKRmwo ",
    director: ["R. Ravikumar"],
    assistantDirector: [],
    producedby: ["R.D. Raja, ", "Ravikumar"],
    cast: [
      "Sivakarthikeyan, ",
      "Rakul Preet Singh, ",
      "Bhanupriya, ",
      "Yogi Babu",
    ],
    crew: [],
    description:
      "A lost alien seeks help to go back to his home, but everything gets harder after the alien returns to its home.",
    imdblink: "https://www.imdb.com/title/tt7892066/?ref_=ext_shr_lnk",
    writer: ["R. Ravikumar"],
    music: ["A.R. Rahman"],
    year: "2024",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m41",
    title: "Siren",

    image:
      "https://www.themoviedb.org/t/p/original/jYwhgaPwDbgC1MneMsAZTkcPcLJ.jpg",

    video: "https://www.youtube.com/embed/iH1nnhSw2lU",
    director: ["Anthony Bhagyaraj"],
    assistantDirector: [],
    producedby: ["Omaar, ", "Sujatha Vijayakumar, ", "Anusha Vijaykumar"],
    cast: ["Yogi Babu. ", "Keerthy Suresh, ", "	Jayam Ravi, ", "Samuthirakani"],
    crew: [],
    description:
      "a revenge thriller about a former police officer who is framed for the murder of a powerful man after the man rapes his wife",
    imdblink: "https://www.imdb.com/title/tt21935200/?ref_=ext_shr_lnk",
    writer: ["Anthony Bhagyaraj"],
    music: ["G.V. Prakash Kumar	"],
    year: "2023",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m42",
    title: "Buddy",

    image:
      "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-250,h-390/et00360905-sjlwqmsexz-portrait.jpg",

    video: "https://www.youtube.com/embed/o1yz_rUkdeI",
    director: ["Sam Anton"],
    assistantDirector: [],
    producedby: ["K.E. Gnanavelraja ,", "Neha Gnanavelraja"],
    cast: ["Allu Sirish ,", "Ajmal Ameer ,", "Sriram Reddy Polasane"],
    crew: [],
    description:
      "Buddy is an drama action comedy directed by Sam Anton. The movie casts Allu Sirish,Ajmal Amir,Prisha Rajesh Singh,Mukeshkumar,Mohammed Ali, in the main lead roles. The music was composed by Hiphop Tamizha The film is produced by K.E. Gnanavelraja & Aadhana Gnanavelraja. Under Studio Green Films Private Limited banner.",
    imdblink: "https://www.imdb.com/title/tt26235048/?ref_=ext_shr_lnk",
    writer: ["Sam Anton"],
    music: ["Hiphop Tamizha Adhi"],
    year: "2023",
    dimension: "2D",
    language: ["Telugu"],
    editing: [],
  },
  {
    id: "m43",
    title: "Ko",

    image: "https://static.toiimg.com/photo/msid-61305224/61305224.jpg?52683",

    video: "https://www.youtube.com/embed/v9jbjPRoj8E",
    director: ["K.V. Anand"],
    assistantDirector: [],
    producedby: ["James, ", "Jayaraman, ", "Elred Kumar"],
    cast: ["Jiiva, ", "Ajmal Ameer, ", "Karthika Nair, ", "Piaa Bajpai"],
    crew: [],
    description:
      "Ashwin, a famous photographer, works with Renuka, an investigative journalist. When one of their colleagues, Saro, gets killed during a political meet, Ashwin and Renuka decide to investigate.",
    imdblink: "https://www.imdb.com/title/tt1851988/?ref_=ext_shr_lnk",
    writer: ["K.V. Anand, ", "D. Suresh, ", "A.N. Balakrishnan"],
    music: ["Harris Jayaraj"],
    year: "2011",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m44",
    title: "Kaththi Sandai",

    image: "https://tamilstar.net/uploads/movie/flm4863Kaththi-Sandai.jpg",

    video: "https://www.youtube.com/embed/2K5EYWrZKi4",
    director: ["Suraj"],
    assistantDirector: [],
    producedby: ["Hari Gujjalapudi, ", "S. Nanthagopal"],
    cast: ["Vishal, ", "Tamannaah Bhatia, ", "Vadivelu, ", "Soori"],
    crew: [],
    description:
      "Kaththi Sandai is the story of a farmer who becomes a Robin Hood style thief for the welfare of his village. He steals from the people who deny his village of its basic needs and uses the money for its development.",
    imdblink: "https://www.imdb.com/title/tt6385842/?ref_=ext_shr_lnk",
    writer: ["Suraj"],
    music: ["Hiphop Tamizha Adhi	"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m45",
    title: "Sangili Bungili Kadhava Thorae",

    image: "https://assets.voxcinemas.com/posters/P_HO00004610.jpg",

    video: "https://www.youtube.com/embed/maJM53J6jjo",
    director: ["Ike"],
    assistantDirector: [],
    producedby: ["Atlee"],
    cast: ["Jai, ", "Jiiva, ", "Sri Divya, ", "Akshara Gowda"],
    crew: [],
    description:
      "A young man who vows to buy a mansion of his own, moves his extended family into it without knowing that it is haunted.",
    imdblink: "https://www.imdb.com/title/tt6904340/?ref_=ext_shr_lnk",
    writer: ["Ike"],
    music: ["Vishal, ", "Chandrashekhar"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m46",
    title: "Jaguar",

    image: "https://flxt.tmsimg.com/assets/p20556141_b_v10_ab.jpg",

    video: "https://www.youtube.com/embed/EaA7aIw1dkI",
    director: ["A. Mahadev, ", "Gururaj M. Desai"],
    assistantDirector: [],
    producedby: ["Anitha Kumaraswamy"],
    cast: [" Nikhil Kumar, ", " Deepti Sati, ", "Jagapathi Babu"],
    crew: [],
    description:
      "Krishna, a young medical student, strikes against corrupt figures of power as a vigilante and tries to decribes his father's injustice death while trying to win the love of his friend's sister as a college student.",
    imdblink: "https://www.imdb.com/title/tt6024364/?ref_=ext_shr_lnk",
    writer: ["Gururaj M. Desai, ", "Vishwa Karun, ", "A. Mahadev"],
    music: ["SS Thaman"],
    year: "2016",
    dimension: "2D",
    language: ["Telugu"],
    editing: [],
  },
  {
    id: "m47",
    title: "Aadhalal Kadhal Seiveer",

    image:
      "https://www.themoviedb.org/t/p/original/t6OO6zU9dYdh9IWNbiWNhZNZaAk.jpg",

    video: "https://www.youtube.com/embed/SGIp0SfLux4",
    director: ["Suseenthiran"],
    assistantDirector: [],
    producedby: ["Thai Saravanan"],
    cast: [
      "Santhosh Ramesh, ",
      "Manisha Yadav, ",
      "	Poornima Jayaram, ",
      "V. Jayaprakash",
    ],
    crew: [],
    description:
      "A love affair begins harmless, but it later turns out to be a huge problem for the pair.",
    imdblink: "https://www.imdb.com/title/tt2419576/?ref_=ext_shr_lnk",
    writer: ["Leninbharati, ", "Suseenthiran, ", "Nagarajan"],
    music: ["Yuvan Shankar Raja	"],
    year: "2013",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m48",
    title: "Vedi",

    image:
      "https://moviegalleri.net/wp-content/gallery/vishal-vedi-movie-posters/vedi_tamil_movie_posters_237.jpg",

    video: "https://www.youtube.com/embed/JJOeDrulS4k",
    director: ["Prabhu Deva"],
    assistantDirector: [],
    producedby: ["Vikram Krishna, ", "Sanjai Lalwani"],
    cast: ["Vishal, ", "Sameera Reddy, ", "	Poonam Kaur, ", "Vivek"],
    crew: [],
    description: "A man searches for his long lost sister.",
    imdblink: "https://www.imdb.com/title/tt2073138/?ref_=ext_shr_lnk",
    writer: ["Siva, ", "Ravi Chakkravarthy, ", "Prabhu Deva"],
    music: ["Vijay Antony"],
    year: "2011",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m49",
    title: "Hyper",

    image:
      "https://files.prokerala.com/movies/pics/800/ram-pothineni-s-hyper-first-look-60170.jpg",

    video: "https://www.youtube.com/embed/b6j1YSH4dzo",
    director: ["Santosh Srinivas"],
    assistantDirector: [],
    producedby: ["Gopichand Achanta, ", "Ram Achanta, "],
    cast: ["Ram Pothineni, ", "Raashi Khanna, ", "Sathyaraj, ", "Rao Ramesh"],
    crew: [],
    description:
      "Surya is overprotective about his father, Narayana Murthy, who is an honest government officer. When Narayana Murthy locks horns with a powerful minister, Surya takes matters into his own hands.",
    imdblink: "https://www.imdb.com/title/tt6128292/?ref_=ext_shr_lnk",
    writer: ["Rishabh Purohit, ", "Abburi Ravi, ", "Santosh Srinivas"],
    music: ["Ghibran"],
    year: "2016",
    dimension: "2D",
    language: ["Telugu"],
    editing: [],
  },
  {
    id: "m50",
    title: "Kaathiruppor Pattiyal",

    image: "https://timesofindia.indiatimes.com/photo/61982319.cms",

    video: "https://www.youtube.com/embed/qBVhB-pEzjU",
    director: ["Balaiya Rajasekhar"],
    assistantDirector: [],
    producedby: ["Baija Tom"],
    cast: [
      "Appukutty, ",
      "Chitra Lakshmanan, ",
      "Motta Rajendran, ",
      "Nandita Shwetha, ",
    ],
    crew: [],
    description:
      "Sathya, a young man, panics when his lover Megha agrees for an arranged marriage as per her father's wish. Tension rises when an RPF officer arrests him when he is on his way to meet her.",
    imdblink: "https://www.imdb.com/title/tt8176308/?ref_=ext_shr_lnk",
    writer: [],
    music: ["Sean Roldan"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m51",
    title: "Oru Oorla Rendu Raja",

    image:
      "https://www.cinema.com.my/images/movies/2014/7oruoorlarenduraja00_450.jpg",

    video: "https://www.youtube.com/embed/O7dvxtSYjSE",
    director: ["R. Kannan"],
    assistantDirector: [],
    producedby: ["R. Kannan, ", "S. Michael Rayappan, ", "M. Sheraphin Xavier	"],
    cast: ["Vimal, ", "Priya Anand, ", "Soori, ", "Nassar"],
    crew: [],
    description:
      "Two wastrels discover their purpose in life when a doctor gets into a legal tangle with a factory owner.",
    imdblink: "https://www.imdb.com/title/tt4047132/?ref_=ext_shr_lnk",
    writer: ["R. Kannan"],
    music: ["D. Imman"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m52",
    title: "Vei Raja Vei",

    image:
      "https://www.themoviedb.org/t/p/original/i83uMY0gieGO529GaeCOh8m0bBJ.jpg",

    video: "https://www.youtube.com/embed/gZhj9cZHum8",
    director: ["Aishwarya Dhanush"],
    assistantDirector: [],
    producedby: ["Kalpathi S. Agoram	"],
    cast: [
      "Gautham Karthik, ",
      "Priya Anand, ",
      "Taapsee Pannu, ",
      "Vivek, ",
      "Daniel Balaji",
    ],
    crew: [],
    description: "A man with the power of intuition gets into gambling.",
    imdblink: "https://www.imdb.com/title/tt3681470/?ref_=ext_shr_lnk",
    writer: ["Aishwarya Dhanush, ", "Madhan Karky"],
    music: ["Yuvan Shankar Raja	"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m53",
    title: "Balloon",

    image:
      "https://www.gethucinema.com/wp-content/uploads/2017/02/C49TGL3WcAAkM48.jpg",

    video: "https://www.youtube.com/embed/AbyOzj2B4ts",
    director: ["Sinish Sreedharan"],
    assistantDirector: [],
    producedby: ["Dhilip Subbarayan, ", "Arun Balaji, ", "Nandakumar"],
    cast: ["Jai, ", "Anjali, ", "Janani, ", "Yogi Babu"],
    crew: [],
    description:
      "Jeeva heads to a haunted house in Ooty where strange events start unfolding around him. The answer to which may lie in his past",
    imdblink: "https://www.imdb.com/title/tt7041458/?ref_=ext_shr_lnk",
    writer: ["Sinish"],
    music: ["Yuvan Shankar Raja"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m54",
    title: "Amara Kaaviyam",

    image:
      "https://www.filmibeat.com/img/220x80x275/popcorn/movie_posters/amara-kaaviyam-20140519175438-13651.jpg",

    video: "https://www.youtube.com/embed/qL-5hLpn_YA",
    director: ["Jeeva Shankar"],
    assistantDirector: [],
    producedby: ["Arya"],
    cast: ["Sathya, ", "Miya George, ", "	Thambi Ramaiah"],
    crew: [],
    description: "A teenage love story develops parental complications.",
    imdblink: "https://www.imdb.com/title/tt3646292/?ref_=ext_shr_lnk",
    writer: ["Jeeva Shankar"],
    music: ["Ghibran, ", "Dominik Johnson"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m55",
    title: "Ishtam",

    image:
      "https://eros-asset-msp.airtelxstream.in/assets/EROSNOW/MOVIE/6103f8a45e85b5521ade75a7/images/1063344_6877997.jpg",

    video: "https://www.youtube.com/embed/b_qyZn-IsC0",
    director: ["Sibi Malayil"],
    assistantDirector: [],
    producedby: ["David Kachappally"],
    cast: ["Dileep, ", "Navya Nair, ", "Nedumudi Venu"],
    crew: [],
    description:
      "A tale that narrates the healthy relationship between a father and his son.",
    imdblink: "https://www.imdb.com/title/tt0322788/?ref_=ext_shr_lnk",
    writer: ["Kalavur Ravikumar"],
    music: ["Mohan Sitara"],
    year: "2001",
    dimension: "2D",
    language: ["Malayalam"],
    editing: [],
  },
  {
    id: "m56",
    title: "Chennai to Singapore",

    image:
      "https://stat4.bollywoodhungama.in/wp-content/uploads/2016/03/films14.jpg",

    video: "https://www.youtube.com/embed/DJUMOyiUOag",
    director: ["Abbas Akbar"],
    assistantDirector: [],
    producedby: ["Erdinc Turan"],
    cast: [
      "Gokul Anand",
      "Rajesh Balachandiran",
      "Emcee Jesz",
      "Shiva Keshav",
      "Anju Kurian",
    ],
    crew: [],
    description:
      "The film story is said to be a romantic comedy about a struggling Indian filmmaker, Harish, who makes his way to Singapore to look for investors to finance his dream movie.",
    imdblink: "https://www.imdb.com/title/tt7055824/?ref_=ext_shr_lnk",
    writer: ["Abbas Akbar"],
    music: ["Ghibran"],
    year: "",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m57",
    title: "Velainu Vandhutta Vellaikaran",

    image:
      "https://m.media-amazon.com/images/M/MV5BMmJiYjM5ZjQtMGYwNS00N2NkLTk0MmMtMWFlN2RiNTY4Y2MxXkEyXkFqcGdeQXVyODIwMDI1NjM@.V1.jpg",

    video: "https://www.youtube.com/embed/h3FCn_QYf8g",
    director: ["Ezhil"],
    assistantDirector: [],
    producedby: ["Ezhil, ", "Rajan Natraj, ", "Vishnu Vishal"],
    cast: ["Vishnu Vishal, ", "Nikki Galrani, ", "Soori, ", "Robo Shankar"],
    crew: [],
    description:
      "A man tries to ask for a favor from a politician to impress the girl he loves until he goes into a coma which further complicates things for him.",
    imdblink: "https://www.imdb.com/title/tt5774904/?ref_=ext_shr_lnk",
    writer: ["Ezhil", "Rishabh Purohit"],
    music: ["C. Sathya"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m58",
    title: "Inidhu Inidhu",

    image:
      "https://m.media-amazon.com/images/M/MV5BMTA4OTk5ODUtNmUwZi00MTI0LTgyMjItODU0YjNlZWQwOWEwXkEyXkFqcGdeQXVyMjAzMjcxNTE@.V1.jpg",

    video: "https://www.youtube.com/embed/jiy4UZ34Zps",
    director: ["K.V. Guhan"],
    assistantDirector: [],
    producedby: ["Prakash Raj"],
    cast: ["Abhishek", "Sharran Kumar", "	Reshmi Menon", "Thrigun"],
    crew: [],
    description:
      "The story revolves around a large group of friends who are freshers in an engineering college. Battling the years of college life, blended with a good dose of fun, most of them end up as pairs.",
    imdblink: "https://www.imdb.com/title/tt1997390/?ref_=ext_shr_lnk",
    writer: ["K.V. Guhan"],
    music: ["Mickey J. Meyer"],
    year: "2010",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m59",
    title: "100",

    image:
      "https://www.behindwoods.com/tamil-movies/100/stills-photos-pictures/100-stills-photos-pictures-01.jpg",

    video: "https://www.youtube.com/embed/SUIipZ_4q64",
    director: ["Sam Anton"],
    assistantDirector: [],
    producedby: ["Kaviya Mahesh"],
    cast: ["Atharvaa Murali", "Hansika Motwani", "Radha Ravi"],
    crew: [],
    description:
      "A young cop, who works in a police control room, tries to solve the mysterious case of a kidnapped girl.",
    imdblink: "https://www.imdb.com/title/tt8458736/?ref_=ext_shr_lnk",
    writer: ["Sam Anton"],
    music: ["Sam C.S."],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m60",
    title: "Adangamaru",

    image:
      "https://tkimages.blob.core.windows.net/productimages/shortads4/shortads4/1612288143-1800969615-adanga-maru.jpg",

    video: "https://www.youtube.com/embed/LablWjpJrLs",
    director: ["Karthik Thangavel"],
    assistantDirector: [],
    producedby: ["Sujatha Vijayakumar ,", "Anand Joy"],
    cast: ["Jayam Ravi ,", "Raashi Khanna ,", "Ponvannan ,", "Sampath Raj"],
    crew: [],
    description:
      "A sincere cop suspended from the department for locking horns with a few influential people in the society, starts taking revenge against those who finished off his dear ones.",
    imdblink: "https://www.imdb.com/title/tt8364418/?ref_=ext_shr_lnk",
    writer: ["Karthik Thangavel ,", "Viji"],
    music: ["Sam C.S."],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m61",
    title: "Samar",

    image:
      "https://media-cache.cinematerial.com/p/500x/fsxtqhlv/samar-indian-movie-poster.jpg?v=1456587314",

    video: "https://www.youtube.com/embed/TrGD6aOyBX4",
    director: ["Thiru"],
    assistantDirector: [],
    producedby: ["T. Ramesh"],
    cast: [
      "Vishal, ",
      "Trisha Krishnan, ",
      "	Sunaina, ",
      "	Manoj Bajpayee, ",
      "J.D. Chakravarthi, ",
    ],
    crew: [],
    description:
      "A Forest-trek guide leaves to Thailand in search of his lady love and gets stuck in a web of bizarre events.",
    imdblink: "https://www.imdb.com/title/tt2187114/?ref_=ext_shr_lnk",
    writer: ["Thiru"],
    music: ["Yuvan Shankar Raja"],
    year: "2013",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m62",

    title: "Gorilla",

    image: "https://static.moviecrow.com/gallery/20190326/159109-gorrilla.jpg",

    video: "https://www.youtube.com/embed/w5vPxAyaWZ8",
    director: ["Don Sandy"],
    assistantDirector: [],
    producedby: ["Rengarajan", "Jaiprakash", "Kiran Talasila"],
    cast: [
      "Jeeva, ",
      "Shalini Pandey, ",
      "Sathish, ",
      "Yogi Babu, ",
      "Motta Rajendran",
    ],
    crew: [],
    description:
      "Three friends who need money to better their lives and a disillusioned farmer hatch a plan to rob a bank with a chimp as an accomplice.",
    imdblink: "https://www.imdb.com/title/tt8011348/?ref_=ext_shr_lnk",
    writer: ["Don Sandy"],
    music: ["Sam C.S."],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: ["Antony L. Ruben"],
  },
  {
    id: "m63",

    title: "Aaha Kalyanam",

    image:
      "https://moviegalleri.net/wp-content/gallery/aaha-kalyanam-movie-release-posters/aaha_kalyanam_movie_release_posters_nani_vaani_kapoor_168e786.jpg",

    video: "https://www.youtube.com/embed/1qUqCR0tmiU",
    director: ["Gokul Krishna"],
    assistantDirector: [],
    producedby: [
      "Aditya Chopra, ",
      "Vijay Amritharaj, ",
      "Deepan Boopathy, ",
      "Venu Padam Kumar, ",
      "Aashish Singh",
    ],
    cast: ["Nani, ", "Vaani Kapoor, ", "Badava Gopi"],
    crew: [],
    description:
      "Partners of a wedding-planning firm face problems when they fall in love.",
    imdblink: "https://www.imdb.com/title/tt2995992/?ref_=ext_shr_lnk",
    writer: ["Habib Faisal, ", "Rajiv Rajaram, ", "Maneesh Sharma"],
    music: ["Dharan Kumar"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m64",

    title: "Silukkuvarupatti Singam",

    image:
      "https://m.media-amazon.com/images/M/MV5BMmFhNmYyYjMtZGI0YS00MDIwLWExOGMtNGQ1ZGU5MWYzN2Q4XkEyXkFqcGdeQXVyMTA4NjE0NjEy.V1.jpg",

    video: "https://www.youtube.com/embed/OETXlAEerEo",
    director: ["Chella"],
    assistantDirector: [],
    producedby: ["Aryan Ramesh, ", "Vishnu Vishal"],
    cast: ["Vishnu Vishal, ", "Regina Cassandra, ", "Yogi Babu"],
    crew: [],
    description:
      "A constable who prefers to mind his own business gets into a situation where he has to hide from a most-wanted gangster",
    imdblink: "https://www.imdb.com/title/tt7853260/?ref_=ext_shr_lnk",
    writer: ["Chella Ayyavu"],
    music: ["Leon James"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m65",

    title: "Yatchan",

    image:
      "https://cdn.filmistreet.com/wp-content/uploads/2015/08/Yatchan2.jpg",

    video: "https://www.youtube.com/embed/RDu1BntWaeE",
    director: ["Vishnuvardhan"],
    assistantDirector: [],
    producedby: [
      "Siddharth Roy Kapur, ",
      "Ronnie Screwvala, ",
      "Vishnuvardhan",
    ],
    cast: ["Arya"],
    crew: [],
    description:
      "Two passionate people with dreams swap places accidentally by fate and their common key to get back their lost life is to defeat the antagonist through the girl whom he wants dead since she can foresee the future.",
    imdblink: "https://www.imdb.com/title/tt4328890/?ref_=ext_shr_lnk",
    writer: ["Vishnuvardhan, ", "D. Suresh, ", "A.N. Balakrishnan"],
    music: ["Yuvan Shankar Raja	"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m66",

    title: "K-13",

    image: "https://nettv4u.com/imagine/07-01-2019/k-13-movie-poster-3.jpg",

    video: "https://www.youtube.com/embed/-uc0YFYYeCw",
    director: ["Barath Neelakantan"],
    assistantDirector: [],
    producedby: ["S.P. Shankar", "Santha Priya"],
    cast: ["Arulnithi, ", "Shraddha Srinath, ", "Yogi Babu"],
    crew: [],
    description:
      "An inebriated filmmaker finds himself in a shocking situation when he realises that the mysterious girl who accompanied him to her room has been killed.",
    imdblink: "https://www.imdb.com/title/tt10275440/?ref_=ext_shr_lnk",
    writer: ["Barath Neelakantan"],
    music: ["Sam C.S."],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m67",

    title: "Nibunan",

    image:
      "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-250,h-390/et00058393-axjslsxmet-portrait.jpg",

    video: "https://www.youtube.com/embed/1y6Q82AZQxw",
    director: ["Arun Vaidyanathan"],
    assistantDirector: [],
    producedby: [" Arun Vaidyanathan, ", "Sudhan, ", "Jayaram, ", "Umesh"],
    cast: [
      "Arjun Sarja, ",
      "Varalaxmi Sarathkumar, ",
      "Prasanna, ",
      "Vaibhav Reddy",
    ],
    crew: [],
    description:
      "Along with his partners Inspector Joseph and Vandhana, DSP Ranjith Kalidoss attempts to hunt down a psychotic killer before realizing he's himself suffering from an unwanted disorder.",
    imdblink: "https://www.imdb.com/title/tt5159408/?ref_=ext_shr_lnk",
    writer: ["Anand Raghav, ", "Arun Vaidyanathan"],
    music: ["Navin"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m68",

    title: "Bakrid",

    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/2f134769294249.5b7c16e62a71d.jpg",

    video: "https://www.youtube.com/embed/Y2hl7sMuJsc",
    director: ["Jagadeesan Subu"],
    assistantDirector: [],
    producedby: ["M.S. Murugaraj Mallaika"],
    cast: ["Vikranth, ", "Sarah, ", "Vasundhara Kashyap, ", "Rohit Pathak"],
    crew: [],
    description:
      "A man develops friendship with a camel, and has to embark on a journey with the animal.",
    imdblink: "https://www.imdb.com/title/tt10731866/?ref_=ext_shr_lnk",
    writer: ["Jagadeesan Subu"],
    music: ["D. Imman"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m69",

    title: "Ayogya",

    image:
      "https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/ayogya-tamil-et00088789-19-11-2018-08-29-25.jpg",

    video: "https://www.youtube.com/embed/xD66V8295V8",
    director: ["Venkat Mohan"],
    assistantDirector: [],
    producedby: ["Madhu B, ", "Praveen Daniel, ", "Bhuvan Sagar"],
    cast: [
      "	Vishal, ",
      "Parthiban Radhakrishnan, ",
      "	K.S. Ravikumar, ",
      "	Raashi Khanna, ",
    ],
    crew: [],
    description:
      "A corrupt police officer finds his life changing when he takes on a case of gang rape.",
    imdblink: "https://www.imdb.com/title/tt9304360/?ref_=ext_shr_lnk",
    writer: ["Venkat Mohan, ", "Vakkantham Vamsi"],
    music: ["Sam C.S."],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m70",

    title: "Enakku Innoru Per Irukku",

    image:
      "https://m.media-amazon.com/images/S/pv-target-images/0f17e807d0d5ecec2872129f17a05dd9578ec7ee12c9201b36e74545f18670ae.jpg",

    video: "https://www.youtube.com/embed/T5km07kjOYA",
    director: ["Sam Anton"],
    assistantDirector: [],
    producedby: ["A. Subaskaran"],
    cast: ["G.V. Prakash Kumar, ", "Anandhi, ", "	Nirosha, ", "Motta Rajendran"],
    crew: [],
    description:
      "The film is an action entertainer where G V Prakash will be seen in the role of an auto rickshaw driver named Johnny",
    imdblink: "https://www.imdb.com/title/tt5814958/?ref_=ext_shr_lnk",
    writer: ["Sam Anton"],
    music: ["G.V. Prakash Kumar"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m71",

    title: "Kanchana 3",

    image:
      "https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/kanchana-3-et00094181-16-01-2019-09-58-50.jpg",

    video: "https://www.youtube.com/embed/-16MdQ9Blgk",
    director: ["Raghava Lawrence"],
    assistantDirector: [],
    producedby: ["Raghava Lawrence, ", "Kalanithi Maran, ", "Hari Vishnu"],
    cast: [
      "Raghava Lawrence, ",
      "Oviya, ",
      "	Vedhika, ",
      "Nikki Tamboli, ",
      "	Kovai Sarala, ",
      "Soori",
    ],
    crew: [],
    description:
      "A young man who gets easily scared is possessed by a ghost that is seeking revenge.",
    imdblink: "https://www.imdb.com/title/tt8042248/?ref_=ext_shr_lnk",
    writer: ["Raghava Lawrence"],
    music: [
      "Sekar Sai Bharath, ",
      "Kapilan Kugavel, ",
      "Bharat, ",
      "Madhusudhanan, ",
      "Jesse Samuel, ",
      "Saravedi Saran, ",
      "Raj Thillaiyampalam",
    ],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m72",

    title: "Kadamban",

    image:
      "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-250,h-390/et00048489-jlmxtxbxjl-portrait.jpg",

    video: "https://www.youtube.com/embed/j7ShfTj2EBU",
    director: ["N. Ragavan"],
    assistantDirector: [],
    producedby: [
      "R.B. Choudary, ",
      "B. Jeevan, ",
      "Jiiva, ",
      "Jithan Ramesh, ",
      "B. Suresha",
    ],
    cast: [
      "Arya, ",
      "Catherine Tresa, ",
      "Catherine Tresa, ",
      "	Y.G. Mahendran",
    ],
    crew: [],
    description:
      "The lives of people living in traditional ways in the South Indian jungle are depicted in this movie.",
    imdblink: "https://www.imdb.com/title/tt6722870/?ref_=ext_shr_lnk",
    writer: ["N. Ragavan, ", "Dev, ", "N. Ragavan"],
    music: ["Yuvan Shankar Raja	"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m73",

    title: "Ranga",

    image:
      "https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/ranga-et00068207-11-05-2022-07-53-31.jpg",

    video: "https://www.youtube.com/embed/ZVl1xNPcBgc",
    director: ["Vinod D.L."],
    assistantDirector: [],
    producedby: ["Vijay K. Celliah, ", "Vijay Kanth R."],
    cast: ["Sibiraj, ", "Nikhila Vimal, ", "Sathish, ", "	Manobala"],
    crew: [],
    description:
      "Aaditya, who has a unique syndrome, falls in love with Abhinaya and marries her. While on their honeymoon in Manali, their life takes a turn. Stuck in the coldest place on earth, will they fight to survive?",
    imdblink: "https://www.imdb.com/title/tt7822416/?ref_=ext_shr_lnk",
    writer: ["Vinod D.L."],
    music: ["Ramjeevan"],
    year: "2022",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m74",

    title: "Vil Ambu",

    image:
      "https://www.themoviedb.org/t/p/w500/z5dna4cgNTeYkbAHrLRnCi5aFPm.jpg",

    video: "https://www.youtube.com/embed/4lZsPiaqgw8",
    director: ["Ramesh Subramaniam"],
    assistantDirector: [],
    producedby: ["Nandakumar, ", "Thai Saravanan, ", "Suseenthiran"],
    cast: [
      "Harish Kalyan, ",
      "	Sri, ",
      "Chandini, ",
      "	Yogi Babu, ",
      "	Samskruthy Shenoy",
    ],
    crew: [],
    description:
      "Vil Ambu is a journey of two characters and talks about how humans are responsible for each other's loss or gain. The story is about how the loss of one person becomes the gain of the other.",
    imdblink: "https://www.imdb.com/title/tt5476166/?ref_=ext_shr_lnk",
    writer: ["Ramesh", "Subramaniam"],
    music: ["Navin"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m75",

    title: "Bagheera",

    image:
      "https://m.media-amazon.com/images/M/MV5BNzVjZTM4ZDMtNDhmOS00ZTc4LTgwNjItMzc5MWZjMTI4NDI3XkEyXkFqcGdeQXVyMTI4Nzc4Njg3._V1_.jpg",

    video: "https://www.youtube.com/embed/S8jbLo47UWA",
    director: ["Adhik Ravichandran, ", "Ravi Kndasamy"],
    assistantDirector: [],
    producedby: [
      "R.V. Bharathan, ",
      "G.Sampath, ",
      "Vijay Kiragandur, ",
      "P Pandiyan, ",
      "S.V.R Ravi Shankar",
    ],
    cast: [
      "Prabhu Deva, ",
      "Amyra Dastur, ",
      "Gopinath Ravi, ",
      "Sakshi Agarwal",
    ],
    crew: [],
    description:
      "Revolves around a psycho killer who has an aversion towards women, thanks to some of the heinous experiences he faced in his life.",
    imdblink: "https://www.imdb.com/title/tt11803630/?ref_=ext_shr_lnk",
    writer: ["Adhik Ravichandran"],
    music: ["Ganesan Sekar"],
    year: "2023",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m76",

    title: "Naa Sigappu Manithan",

    image:
      "https://cdn.cinematerial.com/p/297x/2hewswut/naan-sigappu-manithan-indian-movie-poster-md.jpg?v=1456460303",

    video: "https://www.youtube.com/embed/kW4RHEDZyO0",
    director: ["Thiru"],
    assistantDirector: [],
    producedby: ["Vishal, ", "Ronnie Screwvala, ", "Siddharth Roy Kapur"],
    cast: ["Vishal, ", "Lakshmi Menon, ", "Iniya"],
    crew: [],
    description: "A narcoleptic becomes a vigilante when tragedy strikes.",
    imdblink: "https://www.imdb.com/title/tt3569788/?ref_=ext_shr_lnk",
    writer: ["Thiru"],
    music: ["G.V. Prakash Kumar"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m77",

    title: "Sindhubaadh",

    image:
      "https://i0.wp.com/www.socialnews.xyz/wp-content/uploads/2019/01/16/Vijay-Sethupathi-s-Sindhubaadh-Movie-First-Look-HD-Posters-.jpg?fit=1364%2C2048&quality=80&zoom=1&ssl=1",

    video: "https://www.youtube.com/embed/-NqloB3SLl0",
    director: ["S.U. Arun Kumar"],
    assistantDirector: [],
    producedby: ["S.N. Rajarajan", "Shan Sutharsan"],
    cast: ["Vijay Sethupathi, ", "Anjali, ", "Surya Vijay Sethupathi"],
    crew: [],
    description:
      "It's a story of a common man Thiru who ventures out from his comfort zone looking to make money and becomes Sindhubaadh.",
    cast: ["Vijay Sethupathi, ", "Anjali", ""],
    imdblink: "https://www.imdb.com/title/tt9636256/?ref_=ext_shr_lnk",
    writer: ["Arun Kumar"],
    music: ["Yuvan Shankar Raja	"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m78",

    title: "Inime Ippadithaan",

    image:
      "https://images.jdmagicbox.com/comp/jd_social/news/2018aug08/image-211843-ibxjpcxo5v.jpg",

    video: "https://www.youtube.com/embed/aK3c9GWCGDM",
    director: ["Muruganand"],
    assistantDirector: [],
    producedby: ["Santhanam"],
    cast: ["Santhanam, ", "Ashna Zaveri"],
    crew: [],
    description:
      "Santhanam plays the role of a jobless youth whose only ambition in life is to marry a beautiful girl. The rest of the story shows how he achieves this with the help of a love guru played by VTV Ganesh.",
    imdblink: "Santhosh Kumar Dhayanidhi",
    writer: ["Muruganand"],
    music: ["Santhosh Kumar Dhayanidhi"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m79",

    title: "Hero",

    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/58351e86979761.5da9c08fe902b.jpg",

    video: "https://www.youtube.com/embed/5FZnvzAA2iQ",
    director: ["P.S. Mithran"],
    assistantDirector: [],
    producedby: ["Kotapadi J. Rajesh"],
    cast: [
      "Sivakarthikeyan, ",
      "	Abhay Deol, ",
      "Arjun Sarja, ",
      "Kalyani Priyadarshan",
    ],
    crew: [],
    description:
      "A fraudster gets to fulfill his childhood dream of being a superhero when he takes on a ruthless businessman whose business strategy is to crush the dreams of children.",
    imdblink: "https://www.imdb.com/title/tt10709232/?ref_=ext_shr_lnk",
    writer: ["Pon Parthiban, ", "P.S. Mithran"],
    music: ["Yuvan Shankar Raja"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m80",

    title: "Yaamirukka Bayamey",

    image:
      "https://m.media-amazon.com/images/M/MV5BYzQ3MzJmMzktOWI0Yi00ZDAxLTlmNTktMDcyNzc2ZjNkMWVlXkEyXkFqcGdeQXVyODk2ODI3MTU@.V1.jpg",

    video: "https://www.youtube.com/embed/7utXPKENd-s",
    director: ["Deekay"],
    assistantDirector: [],
    producedby: ["Jayaraman, ", "Elred Kumar"],
    cast: ["Krishna Sekhar, ", "Rupa Manjari, ", "Karunakaran, ", "Oviya"],
    crew: [],
    description:
      "Is a ghost haunting Kiran's ancestral property? Or is it a conman's master moves?",
    imdblink: "https://www.imdb.com/title/tt3681440/?ref_=ext_shr_lnk",
    writer: ["Deekay, ", "Rishabh Purohit"],
    music: ["S. N. Prasad"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m81",

    title: "Kandaen",

    image: "https://timesofindia.indiatimes.com/photo/61316497.cms",

    video: "https://www.youtube.com/embed/r8IDmSkkqEw",
    director: ["A.C. Mughil"],
    assistantDirector: [],
    producedby: ["Rajesh Gopinathan"],
    cast: ["Shanthnu Bhagyaraj, ", "Rashmi Gautam, ", "Santhanam"],
    crew: [],
    description:
      "Vasant's grandfather sets a condition that he must get married in 30 days. Soon, Vansant falls in love with Narmada and to win her heart, he pretends to be blind",
    imdblink: "https://www.imdb.com/title/tt4328102/?ref_=ext_shr_lnk",
    writer: ["A.C. Mughil"],
    music: ["Vijay Ebenezer"],
    year: "2011",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m82",

    title: "Veerasaivaji",

    image:
      "https://1.bp.blogspot.com/-E1wMQR99XKk/Voe2-bEfoWI/AAAAAAABEzc/YCtCz8YUkMU/s1600/veera_sivaji_first_look_poster.jpg",

    video: "https://www.youtube.com/embed/qyV9whzYgJM",
    director: ["Ganesh Vinayak"],
    assistantDirector: [],
    producedby: ["Nandagopal"],
    cast: ["Yogi Babu, ", "G. Marimuthu, ", "Vikram Prabhu, ", "Shamili"],
    crew: [],
    description:
      "A taxi driver has a series of adventures on the journey from Puducherry to Kanyakumari.",
    imdblink: "",
    writer: ["Ganesh Vinayak, ", "Gnanagiri"],
    music: ["D. Imman"],
    year: "2016",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m83",

    title: "Kallapadam",

    image:
      "https://m.media-amazon.com/images/M/MV5BMDg2YmE5NDAtOGMxMC00ODNjLTg3M2YtYmEwOTJjM2E4MTIzXkEyXkFqcGdeQXVyMTE2NjQ5MDI4.V1.jpg",

    video: "https://www.youtube.com/embed/vLFpSYCfxFU",
    director: ["J. Vadivel"],
    assistantDirector: [],
    producedby: ["Anand Ponniraivan"],
    cast: [
      "Lakshmi Priyaa Chandramouli, ",
      "Senthil, ",
      "Damodharan K, ",
      "Aadukalam Naren",
    ],
    crew: [],
    description:
      "Vadivel and his friends specialize in different aspects of film making and decided to make their first movie as one team. Vadivel wants his first film to be his story based on Tamil folklore that is also close to his heart since his father was a performer of the dying rural art form -'Koothu'. But every producer he meets, reject his story on grounds of absence of elements that would make it commercially viable and deem it too risky an option for a debut. Driven to the limits of desperation and not wanting to jeopardize the careers of his friends, he puts forth a plan to fund the movie by executing a heist. The turn of events once the team agrees to the plan is detailed to form the rest of the movie.PipingHotViews'Kallappadam' is all about spontaneity and passion for cinema. Produced by Anand Ponniraivan under the banner Iraivan films, our main focus is to deliver quality low budget films with a message presented in the conventional cinema format. There are a lot of people who are passionate about film-making, but not many get to live the dream. we decided to independently produce this film and have successfully delivered it on time. After conceiving the plot and the story line we came up with the novel idea of casting the Director,Cinematographer, Editor and the Music director in the movie. they play roles as themselves. this has never been tried before. Ironically, the film portrays the struggles of film-making and succeeding in the industry. we wanted to present a message to the audience, especially the next generation. In Kallappadam, we have chosen the ancient folk art 'Koothu' as the core element and told a story in a commercial and entertaining manner not compromising on the quality. the film has been produced by two friends and the technical crew and cast are a bunch of friends and together we all had passion for cinema as the common language.",
    imdblink: "https://www.imdb.com/title/tt4541748/?ref_=ext_shr_lnk",
    writer: ["J. Vadivel"],
    music: ["K Krishna Kumar"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m84",
    title: "Velaikkaran",

    image:
      "https://m.media-amazon.com/images/M/MV5BNWNiYjEwYzgtYmJjMC00ZTVhLTkzNGEtNjE5MjE2ZTM1NmQyXkEyXkFqcGdeQXVyNjkwOTg4MTA@.V1.jpg",

    video: "https://www.youtube.com/embed/XCFNH1Bo0eo",
    director: ["Mohan Raja"],
    assistantDirector: [],
    producedby: ["R.D. Raja"],
    cast: [
      "Sivakarthikeyan, ",
      "Fahadh Faasil, ",
      "Nayanthara, ",
      "Prakash Raj, ",
      "Sneha",
    ],
    crew: [],
    description:
      "A youngster from the slums battles against food adulteration by the company that employs him.",
    imdblink: "",
    writer: ["Mohan Raja"],
    music: ["Anirudh Ravichander"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m85",

    title: "Gurkha",

    image:
      "https://m.media-amazon.com/images/M/MV5BMzVjMTY3NjgtNDVmYS00MzZkLWE3NTItMzkwYTllODI0MWUxXkEyXkFqcGdeQXVyODIwMDI1NjM@.V1.jpg",

    video: "https://www.youtube.com/embed/TGp_PkIlvPM",
    director: ["Sam Anton"],
    assistantDirector: [],
    producedby: ["Sam Anton"],
    cast: ["Yogi Babu, ", "Charlie, ", "Devadarshini Chetan, ", "Manobala"],
    crew: [],
    description:
      "The security guard of a mall, who belongs to the Gurkha community, becomes the saviour of several people who have been kept under siege by some terrorists.",
    imdblink: "https://www.imdb.com/title/tt9104664/?ref_=ext_shr_lnk ",
    writer: ["Sam Anton"],
    music: ["Raj Aryan"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m86",

    title: "Airaa",

    image:
      "https://a10.gaanacdn.com/images/albums/85/2397385/crop_480x480_1551360459_2397385.jpg",

    video: "https://www.youtube.com/embed/V0JBOhkml14",
    director: ["Sarjun"],
    assistantDirector: [],
    producedby: ["Kotapadi J. Rajesh"],
    cast: ["Nayanthara, ", "Kalaiyarasan, ", "Yogi Babu, ", "Krishna Abhishek"],
    crew: [],
    description:
      "A young woman, who goes to her grandmother's home in Pollachi, realises the presence of something supernatural in the place. Meanwhile, mysterious murders are happening in Chennai. How are these seemingly unrelated events connected?",
    imdblink: "https://www.imdb.com/title/tt9319896/?ref_=ext_shr_lnk",
    writer: ["Priyanka Ravindran"],
    music: ["K.S. Sundaramurthy	"],
    year: "2019",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m87",

    title: "Thoranai",

    image:
      "https://images.moviebuff.com/2e29a37f-8f4b-4037-87ee-a2d87affa9d3?w=600",

    video: "https://www.youtube.com/embed/DGAtDvNU0As",
    director: ["Sabha Ayyappan"],
    assistantDirector: [],
    producedby: ["Vikram Krishna"],
    cast: [
      "Vishal, ",
      "Shriya Saran, ",
      "Mohammad Ali, ",
      "	Prakash Raj, ",
      "Santhanam, ",
    ],
    crew: [],
    description:
      "A man comes to Chennai with a mission to find his brother, who had run away from his house about two decades ago. But a series of events make them both a part of unexpected happenings.",
    imdblink: "https://www.imdb.com/title/tt2690596/?ref_=ext_shr_lnk",
    writer: ["Mohan Nair, ", "Shashank Vennelakanti"],
    music: ["Mani Sharma"],
    year: "2009",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m88",

    title: "Kanaa",

    image:
      "https://e1.pxfuel.com/desktop-wallpaper/129/545/desktop-wallpaper-kanaa-thumbnail.jpg",

    video: "https://www.youtube.com/embed/_6_8uzD3EYM",
    director: ["Arunraja Kamaraj"],
    assistantDirector: [],
    producedby: ["Sivakarthikeyan"],
    cast: ["Aishwarya Rajesh, ", "	Sathyaraj, ", "Sivakarthikeyan, ", "Darshan"],
    crew: [],
    description:
      "A woman who hails from a middle-class family background aims to excel in the competitive world of cricket with the help of her supportive father.",
    imdblink: "https://www.imdb.com/title/tt8458718/?ref_=ext_shr_lnk",
    writer: ["Mona Arya, ", "Arunraja Kamaraj"],
    music: ["Dhibu Ninan Thomas	"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },

  {
    id: "m89",
    title: "Muppozhudhum un Karpanaigal",

    image:
      "https://i0.wp.com/3.bp.blogspot.com/_Lb7wWaGboOw/TTjkIofq1xI/AAAAAAAACTo/rbHwabjso94/s1600/Muppozhudhum_Un_Karpanaigal_Movie_Posters_02.jpg",

    video: "https://www.youtube.com/embed/77A-jJ-2kfE",
    director: ["Elred Kumar"],
    assistantDirector: [],
    producedby: ["James, ", "Jayaraman"],
    cast: ["Atharvaa Murali, ", "Amala Paul, ", "Anupama Kumar, ", "Santhanam"],
    crew: [],
    description:
      "An IT professional falls in love badly with a girl with a mysterious background.",
    imdblink: "",
    writer: ["Elred Kumar"],
    music: ["G.V. Prakash Kumar	"],
    year: "2012",
    dimension: "2D",
    language: ["Tamil ", "Hindi"],
    editing: [],
  },
  {
    id: "m90",
    title: "Jeeva",

    image:
      "https://m.media-amazon.com/images/I/71FuaRuk5yL._AC_UF1000,1000_QL80_.jpg",

    video: "https://www.youtube.com/embed/NvEJOGoSfMo",
    director: ["Suseenthiran"],
    assistantDirector: [],
    producedby: [
      "Arya ,",
      "R. Madhie ,",
      "Rajeevan ,",
      "Suseenthiran ,",
      "Vishal",
    ],
    cast: ["Vishnu Vishal ,", "	Sri Divya ,", "Soori ,", "Lakshman Narayan"],
    crew: [],
    description:
      "Right from his childhood, the motherless Jeeva grows with his focus fully on Cricket. However, it is never smooth sailing for him with disruptions coming in various forms ranging from his love life to team selection politics",
    imdblink: "https://www.imdb.com/title/tt4047112/?ref_=ext_shr_lnk",
    writer: ["Suseenthiran ,", "Arun Balaji ,", "Santhosh"],
    music: ["D. Imman"],
    year: "2014",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m91",
    title: "Trisha Ilana Nayanthara",

    image: "https://picfiles.alphacoders.com/392/thumb-392455.jpg",

    video: "https://www.youtube.com/embed/LgGriiQ5u3w",
    director: ["Adhik Ravichandran"],
    assistantDirector: [],
    producedby: ["C.J. Jayakumar"],
    cast: [
      "G.V. Prakash Kumar, ",
      "Anandhi, ",
      "Manisha Yadav, ",
      "Simran, ",
      "Robo Shankar",
    ],
    crew: [],
    description:
      "Jeeva goes through 3 different stages of life - from school, to college and career. It talks about the views of youth on love. The story underlines the importance of not giving up hope after a break-up.",
    imdblink: "https://www.imdb.com/title/tt5029004/?ref_=ext_shr_lnk",
    writer: ["Adhik Ravichandran"],
    music: ["G.V. Prakash Kumar	"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m92",
    title: "Aramm",

    image:
      "https://m.media-amazon.com/images/M/MV5BYzA0MTc1MGQtYWZjNC00NjY3LWI0ODctMDhhNWU5NWIzNTY5XkEyXkFqcGdeQXVyMjcyOTUxOTQ@.V1.jpg",

    video: "https://www.youtube.com/embed/hWsn_1cs6Dc",
    director: ["Gopi Nainar"],
    assistantDirector: [],
    producedby: ["Kotapadi Ramesh"],
    cast: ["Nayanthara", "Vela Ramamoorthy", "Ramesh Thilaganathan"],
    crew: [],
    description:
      "A District Collector in India deals with water shortage problems in a village when she comes to realize that people are suffering a lot.",
    imdblink: "https://www.imdb.com/title/tt6270936/?ref_=ext_shr_lnk",
    writer: ["Gopi Nainar, ", "Rishabh Purohit"],
    music: ["Ghibran"],
    year: "2017",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m93",
    title: "Thani Oruvan",

    image:
      "https://m.media-amazon.com/images/M/MV5BOTc3OWQzYjUtNmRiMi00MGUwLWI4ODEtMjU4YWRiYjBlM2Y1XkEyXkFqcGdeQXVyODIwMDI1NjM@.V1.jpg",

    video: "https://www.youtube.com/embed/r5Lih8rKd6k",
    director: ["Mohan Raja"],
    assistantDirector: [],
    producedby: ["Kalpathi S. Agoram"],
    cast: [
      "Jayam Ravi, ",
      "	Arvind Swami, ",
      "Nayanthara, ",
      "Nassar, ",
      "Thambi Ramaiah",
    ],
    crew: [],
    description:
      "Siddharth Abimanyu, an influential scientist, is involved in various illegal medical practices. Mithran, an efficient IPS officer, decides to expose him.",
    imdblink: "https://www.imdb.com/title/tt4987556/?ref_=ext_shr_lnk",
    writer: ["Mohan Raja, ", "D. Suresh"],
    music: ["Hiphop Tamizha Adhi"],
    year: "2015",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m94",
    title: "Kadaikutty Singam",

    image: "https://live.staticflickr.com/4671/40372625472_1a8daab880_b.jpg",

    video: "https://www.youtube.com/embed/v5YO-Zred7w",
    director: ["Pandiraj"],
    assistantDirector: [],
    producedby: ["Suriya"],
    cast: [
      "Karthi, ",
      "Sathyaraj, ",
      "Sayyeshaa Saigal, ",
      "Priya Bhavani Shankar",
    ],
    crew: [],
    description:
      "Gunasingam, a family-loving farmer who hails from a small town, tries his best to keep his big family united despite him being misunderstood by many.",
    imdblink: "https://www.imdb.com/title/tt8023734/?ref_=ext_shr_lnk",
    writer: ["Pandiraj"],
    music: ["D. Imman"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m95",
    title: "Thanaa Serndha Koottam",

    image:
      "https://data1.ibtimes.co.in/photo/en/full/81138/thaanaa-serndha-koottam-upcoming-tamil-comedy-film-written-directed-by-vignesh-shivan.jpg?w=691",

    video: "https://www.youtube.com/embed/hYM_iZII4U4",
    director: ["Vignesh Shivan"],
    assistantDirector: [],
    producedby: ["K.E. Gnanavelraja"],
    cast: ["Suriya, ", "Keerthy Suresh, ", "Karthik, ", "Ramya Krishnan"],
    crew: [],
    description:
      "Angered by the corruption that stopped him from becoming a CBI officer, a young man forms a gang who pose as CBI officers and raids the corrupt.",
    imdblink: "https://www.imdb.com/title/tt6237966/?ref_=ext_shr_lnk",
    writer: ["Neeraj Pandey, ", "Vignesh Shivan"],
    music: ["Anirudh Ravichander"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m96",
    title: "Sandakozhi 2",

    image:
      "https://www.themoviedb.org/t/p/original/AhQKImKreDgfPDLx8HFo1i48Hui.jpg",

    video: "https://www.youtube.com/embed/71uCBBdNqDg",
    director: ["N. Linguswamy"],
    assistantDirector: [],
    producedby: ["Praveen Daniel, ", "Akshay Gada"],
    cast: [
      "Vishal, ",
      "Keerthy Suresh, ",
      "Rajkiran, ",
      "Varalaxmi Sarathkumar, ",
      "'Ganja' Karuppu",
    ],
    crew: [],
    description:
      "Balu and his father have to protect a young man from a woman who has sworn vengeance on his entire clan.",
    imdblink: "https://www.imdb.com/title/tt7938444/?ref_=ext_shr_lnk",
    writer: ["N. Linguswamy, ", "S. Ramakrishnan, ", "Brindha Sarathy"],
    music: ["Yuvan Shankar Raja	"],
    year: "2018",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m97",
    title: "Osthe",

    image:
      "https://m.media-amazon.com/images/S/pv-target-images/a683ff34a33a56e0d976fb4fc89e1cff3b75b07e46a0c10483f1e13b28a2b145.jpg",

    video: "https://www.youtube.com/embed/guX9_V-bmaA",
    director: ["Dharani"],
    assistantDirector: [],
    producedby: ["M. Mohan Apparao, ", "T. Ramesh"],
    cast: ["Silambarasan Rajendar, ", "Richa Langella, ", "Santhanam"],
    crew: [],
    description:
      "A police officer faces challenges from his family, gangsters and politicians and what happens in the end forms the crux of the story.",
    imdblink: "https://www.imdb.com/title/tt2133300/?ref_=ext_shr_lnk",
    writer: ["Bharathan, ", "Abhinav Kashyap"],
    music: ["S. Thaman"],
    year: "2011",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
  {
    id: "m98",
    title: "Pattas",

    image: "https://wallpapercave.com/wp/wp7838888.jpg",

    video: "https://www.youtube.com/embed/FqyayYP36mk",
    director: ["R.S. Durai Senthilkumar"],
    assistantDirector: [],
    producedby: [
      "G. Saravanan, ",
      "Sai Siddharth, ",
      "Arjun Thyagarajan, ",
      "Sendhil Thyagarajan, ",
    ],
    cast: ["Dhanush, ", "Sneha, ", "Mehreen Pirzada, ", "Naveen Chandra"],
    crew: [],
    description:
      "Sakthi is a petty thief in a Chennai slum, who learns the Adimurai, the ancient and oldest form of martial arts. Meanwhile, an evil man and his son challenge Sakthi for a kickboxing tournament, and Sakthi agrees to participate in it.",
    imdblink: "https://www.imdb.com/title/tt10750020/?ref_=ext_shr_lnk",
    writer: ["R.S. Durai Senthilkumar"],
    music: ["Vivek Mervin"],
    year: "2020",
    dimension: "2D",
    language: ["Tamil"],
    editing: [],
  },
];

export default slideData;
