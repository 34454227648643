const companylogo = [
    // {
    //   company_name: 'Red chillies',
    //   company_logo:
    //     'https://upload.wikimedia.org/wikipedia/en/2/21/Red_Chillies_Entertainment_logo.png',
    // },
  {
    company_name: 'Think Music',
    company_logo:
      'https://yt3.googleusercontent.com/ytc/APkrFKZlDeT656oVnMIrgXGvGOEXjmrbVfw2Rfc0sjLr3Q=s900-c-k-c0x00ffffff-no-rj',
  },
  //   {
  //     company_name: 'Kalanithi maaran sun TV',
  //     company_logo:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBTHjq80H5g1_LEovMGqZ6-j7JhAzp0br_8eAQs0CoY3lPWl9x0cxQOet5S7QaTx2ysHI&usqp=CAU',
  //   },
  {
    company_name: 'Mythri Movie Makers',
    company_logo:
      'https://upload.wikimedia.org/wikipedia/en/6/63/Mythri_Movie_Makers_Logo_Srimanthudu.png',
  },
//   {
//     company_name: 'Wunderbar Films',
//     company_logo:
//       'https://upload.wikimedia.org/wikipedia/commons/a/a1/Wunderbar_Films_Logo_White.jpg',
//   },
  {
    company_name: 'Asianet movies',
    company_logo:
      'https://yt3.googleusercontent.com/ytc/APkrFKbaSXor5ffGzsQFdIQ0zSQF4Yg4CyIjR6z5LUY=s900-c-k-c0x00ffffff-no-rj',
  },
  {
    company_name: 'Zee studios',
    company_logo:
      'https://yt3.googleusercontent.com/E46GRICGXEBNGqnrDl8YypgH-Tg4AYm5mgUmaaH3wSVADioHdqD8BYjPnyyeCGEMvXjWlRpg9zg=s900-c-k-c0x00ffffff-no-rj',
  },
  //   {
  //     company_name: 'Sony Music India',
  //     company_logo:
  //       'https://pickle.co.in/wp-content/uploads/2019/10/sony-music.jpg',
  //   },
  //   {
  //     company_name: 'Sathya jothi films',
  //     company_logo:
  //       'https://upload.wikimedia.org/wikipedia/en/d/da/Sathya_Jyothi_Films.png',
  //   },

  //   {
  //     company_name: 'LYCA Production',
  //     company_logo:
  //       'https://lycaproductions.in/wp-content/uploads/2016/06/LYCA._00525-1.png    ',
  //   },
  {
    company_name: 'Moving frame production',
    company_logo:
      'https://yt3.googleusercontent.com/ytc/APkrFKbD-eiYTxCfI5zLSwPMk9_uIicMKxmfRmTra07GFA=s900-c-k-c0x00ffffff-no-rj',
  },
  {
    company_name: 'Prince production',
    company_logo:
      'https://pbs.twimg.com/profile_images/1327138741182410754/rFAxqk_F_400x400.jpg',
  },
  {
    company_name: 'AGS',
    company_logo:
      'https://yt3.googleusercontent.com/6Ys7IUmaraxyk_HQAvbofIYVuSgLJx7BAspxeO6SaPOfOSQQ0KgE51ZjyekqD0ZAlT8w9h0DJYE=s900-c-k-c0x00ffffff-no-rj    ',
  },
  {
    company_name: 'Home movie makers',
    company_logo:
      'https://yt3.googleusercontent.com/ytc/APkrFKaKgNqxr51WcITH95u19IvfDfmQo9iOdXg45NLD=s900-c-k-c0x00ffffff-no-rj',
  },
  //   {
  //     company_name: 'Global Infotainment',
  //     company_logo:
  //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ53rfenEi3NZnzDfObqNuZ3nYZH5uA7POo7g&usqp=CAU',
  //   },
  //   {
  //     company_name: '2D Entertainment',
  //     company_logo:
  //       'https://pbs.twimg.com/ext_tw_video_thumb/1557252631088680960/pu/img/A3odphe6ioHxKphN.jpg:large',
  //   },
  {
    company_name: 'SK Production',
    company_logo:
      'https://pbs.twimg.com/profile_images/995295539091587073/G9w5oxjn_400x400.jpg',
  },
  {
    company_name: 'YM movies production',
    company_logo:
      'https://media.licdn.com/dms/image/C4E0BAQECTbVNYwOnHw/company-logo_200_200/0/1563445707027?e=2147483647&v=beta&t=GRdKAO5HKMQW5R4Z7JqimlrvAtkVgGtF3JS_XrqksGc    ',
  },
  {
    company_name: 'IDEAL Movie production',
    company_logo:
      'https://pbs.twimg.com/profile_images/920636497627189249/wi2_NxoK_400x400.jpg',
  },
  {
    company_name: 'SNS Movie production LLP',
    company_logo:
      'https://pbs.twimg.com/profile_images/1465958925530894337/ExggbySO_400x400.jpg',
  },
  {
    company_name: 'Think studios',
    company_logo:
      'https://thinkbigstudios.film/wp-content/uploads/think-big-studios-logo.jpg',
  },
  //   {
  //     company_name: 'The Legend Saravana Store Production',
  //     company_logo:
  //       'https://akm-img-a-in.tosshub.com/sites/dailyo//resources/202208/of-course-saravana-stores-has-its-own-production-studio-so-that-its-owner-can-star-in-a-movie-source-the-legend-trailer010822040918.png',
  //   },
  {
    company_name: 'Bharathan pictures',
    company_logo:
      'https://pbs.twimg.com/profile_images/1559933603533819904/vSklPzFR_400x400.jpg',
  },
  {
    company_name: 'Pramod Films',
    company_logo:
      'https://pbs.twimg.com/profile_images/1364810666956099584/P2TVYrVq_400x400.jpg',
  },
  //   {
  //     company_name: '',
  //     company_logo: '',
  //   },
  //   {
  //     company_name: '',
  //     company_logo: '',
  //   },
  //   {
  //     company_name: '',
  //     company_logo: '',
  //   },
]
export default companylogo
