import React from "react";
import pdf from "../../assets/RubenTrailerHouse.pdf";

import FileDownloadIcon from '@mui/icons-material/FileDownload';


function DownloadButton() {
    const handleDownload = () => {
        // Define the path to the PDF file
        const pdfFilePath = pdf;

        // Create a hidden anchor element and trigger the download
        const a = document.createElement("a");
        a.href = pdfFilePath;
        a.download = "RubenTrailerHouse.pdf"; // Set the desired filename
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
    };

    return (
        <div>
            <button className="btn btn-secondary btn-sm"
                style={{
                    background: "linear-gradient(to right, rgb(0, 9, 60), rgb(45, 11, 0))"
                }}
                onClick={handleDownload}
            >
                <FileDownloadIcon /> Download Profile
            </button>

        </div>
    );
}

export default DownloadButton;
