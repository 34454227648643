import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import companylogo from "../home/ClientData";
import ContentOne from "../flimography/ContentOne";
import ContentTwo from "../home/ContentTwo";

function ClientPortfolio() {
  const Navigate = useNavigate();
  const data = companylogo;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContentTwo />
      <div className="mx-4 mb-5">
        <Typography
          variant="h6"
          style={{ fontFamily: "Playfair Display", fontSize: "24px" }}
          className="mb-2"
        >
          Clientfolio
        </Typography>
        <div className="row mb-4">
          {data.map((item, index) => (
            <div
              className="col-lg-2 col-md-3 col-sm-6 col-6 mb-2"
              key={index}
              style={{ padding: "4px" }}
            >
              <div className="card cardlogo" style={{ cursor: "", height: "205px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                className="production_logo"
                  src={item.company_logo}
                  style={{
                    maxHeight: "100%",
                    width: "80%",
                    height: "80%",
                    objectFit: "cover",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderRadius: '50%',
                  }}
                  alt={item.altText}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ClientPortfolio;
